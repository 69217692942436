// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { ModalDialogBox } from './modal-dialog-box';
import render from './render';
import ce from './createElement';

import './Popup.less';

class Popup extends ModalDialogBox {
    _initConfiguration(config) {
        super._initConfiguration(config);

        this._popupContentAreaId = `${this._id}-popup-content-area`;
        this._titleAreaId = `${this._id}-title-area`;
        this._actionButtonsId = `${this._id}-action-buttons`;
        this._rightActionButtonsAreaId = `${this._id}-right-action-buttons-area`;

        this._contentHeight = this._getConfigParam('contentHeight', null);
        this._closeButtonEnabled = this._getConfigParam('closeButtonEnabled', false);
        this._cls = this._getConfigParam('popupCls', 'popup-panel');
        this._titleCls = this._getConfigParam('titleCls', '');
        this._buttons = this._getConfigParam('buttons', []);
    }

    _initComponentElement() {
        super._initComponentElement();

        const title = this._getConfigParam('title', '');

        this._updateComponentElement(
            ce('.popup-wrapper',
                ce('.popup-container',
                    ce('.popup-heading',
                        ce('.popup-heading-area',
                            this._closeButtonEnabled ? ce('span.close', {
                                id: `${this._id}-close`,
                                onrender: el => {
                                    el.addEventListener('click', event => {
                                        this._onCloseClick(event);
                                    });
                                },
                            }) : null,
                            ce(`h2.${this._titleCls}`,
                                ce('span', { id: this._titleAreaId, title }, title),
                            ),
                        ),
                    ),
                    ce('.popup-content',
                        ce('.popup-content-area', {
                            id: this._popupContentAreaId,
                            style: this._contentHeight ? `height:${this._contentHeight}px;` : null,
                        }, this._getContentArea()),
                    ),
                ),
            ),
        );
    }

    _getContentArea() {
        let buttonsArea = '';
        if (this._buttons.length > 0) {
            buttonsArea = this._getButtonsArea();
        }
        const formId = `${this.getId()}-form`;
        const content = this._getConfigParam('content', '');

        if (typeof content === 'object') {
            return ce('form', { id: formId }, content, buttonsArea);
        }

        return `<form id="${formId}">${content}${buttonsArea}</form>`;
    }

    _getButtonsArea() {
        return (
            `<div class="btns-box" id="${this._actionButtonsId}">` +
                '<div class="box-area">' +
                    '<div class="form-row">' +
                        '<div class="single-row">' +
                            `<div class="field-value" id="${this._rightActionButtonsAreaId}"> </div>` +
                        '</div>' +
                    '</div>' +
                '</div>' +
            '</div>'
        );
    }

    _addEvents() {
        super._addEvents();

        this._buttons.forEach(buttonDesc => {
            let buttonId = '';
            if ('undefined' !== typeof buttonDesc.id) {
                buttonId = ` id="${buttonDesc.id}" `;
            }
            if ('undefined' === typeof buttonDesc.class) {
                buttonDesc.class = '';
            }
            let buttonDisabled = '';
            if ('undefined' !== typeof buttonDesc.disabled && buttonDesc.disabled) {
                buttonDisabled = 'disabled="disabled"';
            }
            const buttonContainer = document.createElement('span');
            buttonContainer.className = `btn ${buttonDesc.class}`;
            buttonContainer.innerHTML = `<button ${buttonId} value="" type="button" ${buttonDisabled}>${buttonDesc.title}</button>`;
            buttonContainer.querySelector('button').addEventListener('click', event => {
                buttonDesc.handler(event, this);
            });
            render(document.getElementById(this._rightActionButtonsAreaId), buttonContainer);
        });

        this.show();
    }

    _onCloseClick() {
        this.hide();
    }
}

// TODO EXTNODEJS-90: Eliminate Class.create() usage for Jsw classes
// TODO EXTDOCKER-50: Cannot open docker extension: Cannot read property 'push' of undefined
Popup.subclasses = [];

export default Popup;
