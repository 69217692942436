// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import PropTypes from 'prop-types';
import { ListActions, ListAction } from '@plesk/ui-library';
import { Locale } from 'jsw';
import { NotificationPropType } from './lib/PropTypes';

import './NotificationActions.less';

const Translate = Locale.getTranslate('components.notification-center');

const NotificationActions = ({ notification: { id, isFavorite, code, notifications }, onUpdateNotifications, onRemoveNotification }) => {
    const ids = notifications ? notifications.map(notification => notification.id) : [id];
    return (
        <ListActions className="notification-actions">
            <ListAction
                data-action={isFavorite ? 'NOTIFICATION_UNFAVORITE' : 'NOTIFICATION_FAVORITE'}
                data-type={code}
                icon={isFavorite ? 'star-filled' : 'star'}
                primary
                onClick={() => onUpdateNotifications(ids, {
                    isFavorite: !isFavorite,
                })}
            >
                <Translate content={isFavorite ? 'unfavoriteNotification' : 'favoriteNotification'} />
            </ListAction>
            <ListAction
                data-action="NOTIFICATION_DELETE"
                data-type={code}
                icon="recycle"
                primary
                onClick={() => onRemoveNotification(ids)}
            >

                <Translate content={notifications ? 'deleteNotifications' : 'deleteNotification'} />
            </ListAction>
        </ListActions>
    );
};

NotificationActions.propTypes = {
    notification: NotificationPropType.isRequired,
    onUpdateNotifications: PropTypes.func.isRequired,
    onRemoveNotification: PropTypes.func.isRequired,
};

export default NotificationActions;
