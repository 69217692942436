// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import apolloClient from '../apolloClient';
import { gql } from '@apollo/client';
import { redirect } from 'react-router-dom';
import { getId } from 'helpers/getId';
import { Locale } from 'jsw';

const ROUTE = 'summary-report';

export const summaryReportChildrenRoute = {
    path: ROUTE,
    children: [{
        handle: {
            crumb: () => ({
                title: Locale.getSection('components.routes').lmsg('summaryReport'),
            }),
        },
        children: [{
            path: 'layouts',
            componentLoader: () => import(/* webpackChunkName: "components/pages/SummaryReportLayoutsPage" */'components/pages/SummaryReportLayoutsPage'),
            loader: async () => {
                const { data } = await apolloClient.query({
                    query: require('queries/SummaryReportLayoutsPage.graphql'),
                });
                return data;
            },
            handle: {
                crumb: () => ({
                    title: Locale.getSection('components.routes').lmsg('summaryReportLayouts'),
                }),
                helpContext: ({ data: { viewer: type } }) => type === 'ADMIN' ? 'admin.report.layout' : 'reseller.report.layout',
            },
            children: [{
                path: 'add',
                componentLoader: () => import(
                    /* webpackChunkName: "components/pages/SummaryReportLayoutsPage/AddPage" */
                    'components/pages/SummaryReportLayoutsPage/AddPage'
                ),
                loader: async () => {
                    const { data } = await apolloClient.query({
                        query: require('queries/SummaryReportLayoutAddPage.graphql'),
                    });
                    return data;
                },
            }, {
                path: ':layoutId/edit',
                componentLoader: () => import(
                    /* webpackChunkName: "components/pages/SummaryReportLayoutsPage/EditPage" */
                    'components/pages/SummaryReportLayoutsPage/EditPage'
                ),
                loader: async ({ params: { layoutId } }) => {
                    const { data } = await apolloClient.query({
                        query: require('queries/SummaryReportLayoutEditPage.graphql'),
                        variables: { id: btoa(`SummaryReportLayout:${layoutId}`) },
                    });
                    return data;
                },
            }],
        }, {
            path: 'traffic-history',
            componentLoader: () => import(/* webpackChunkName: "components/pages/TrafficHistoryReportPage" */'components/pages/TrafficHistoryReportPage'),
            loader: async () => {
                const { data } = await apolloClient.query({
                    query: require('queries/TrafficHistoryReportPage.graphql'),
                });
                return data;
            },
            handle: {
                crumb: () => ({
                    title: Locale.getSection('components.routes').lmsg('summaryReportTrafficHistory'),
                }),
                helpContext: ({ data: { viewer: type } }) => type === 'ADMIN' ? 'admin.report.traffic-history' : 'reseller.report.traffic-history',
            },
        }],
    }, {
        path: ':layoutId',
        handle: {
            crumb: () => ({
                title: Locale.getSection('components.routes').lmsg('summaryReport'),
            }),
        },
        children: [{
            path: 'schedule',
            componentLoader: () => import(/* webpackChunkName: "components/pages/SummaryReportSchedulesPage" */'components/pages/SummaryReportSchedulesPage'),
            loader: async ({ params: { layoutId } }) => {
                const { data } = await apolloClient.query({
                    query: require('queries/SummaryReportSchedulesPage.graphql'),
                    variables: { id: btoa(`SummaryReportLayout:${layoutId}`) },
                });
                return data;
            },
            handle: {
                crumb: ({ data: { summaryReportLayout: { name } } }) => ({
                    title: Locale.getSection('components.routes').lmsg('summaryReportSchedule', { name }),
                }),
                helpContext: ({ data: { viewer: type } }) => type === 'ADMIN' ? 'admin.report.layout.auto' : 'reseller.report.layout.auto',
            },
            children: [{
                path: 'add',
                componentLoader: () => import(
                    /* webpackChunkName: "components/pages/SummaryReportSchedulesPage" */
                    'components/pages/SummaryReportSchedulesPage/AddPage'
                ),
                loader: async ({ params: { layoutId } }) => {
                    const { data } = await apolloClient.query({
                        query: require('queries/SummaryReportScheduleAddPage.graphql'),
                        variables: {
                            layoutId: btoa(`SummaryReportLayout:${layoutId}`),
                        },
                    });
                    return data;
                },
            }, {
                path: ':scheduleId/edit',
                componentLoader: () => import(
                    /* webpackChunkName: "components/pages/SummaryReportSchedulesPage" */
                    'components/pages/SummaryReportSchedulesPage/EditPage'
                ),
                loader: async ({ params: { scheduleId, layoutId } }) => {
                    const { data } = await apolloClient.query({
                        query: require('queries/SummaryReportScheduleEditPage.graphql'),
                        variables: {
                            scheduleId: btoa(`SummaryReportSchedule:${scheduleId}`),
                            layoutId: btoa(`SummaryReportLayout:${layoutId}`),
                        },
                    });
                    return data;
                },
            }],
        },
        {
            path: 'print',
            componentLoader: () => import(/* webpackChunkName: "components/pages/SummaryReportPage" */'components/pages/SummaryReportPage/SummaryReportPrintPage'),
            loader: async ({ params: { layoutId } }) => {
                await apolloClient.query({
                    query: require('queries/SummaryReportPrintPage.graphql'),
                    variables: { id: btoa(`SummaryReportLayout:${layoutId}`) },
                });
            },
            handle: {
                noLayout: true,
            },
        }],
    },
    ],
};

export const summaryReportRoute = ({ ignoreTopLevelTitle = false } = {}) => ({
    path: ROUTE,
    handle: {
        crumb: () => ignoreTopLevelTitle ? [] : {
            title: Locale.getSection('components.routes').lmsg('summaryReport'),
        },
    },
    children: [{
        index: true,
        handle: {
            crumb: () => ({
                title: Locale.getSection('components.routes').lmsg('summaryReport'),
            }),
        },
        loader: async () => {
            const { data: { viewer: { summaryReportLayouts } } } = await apolloClient.query({
                query: gql`
                    query {
                        viewer {
                            login
                            summaryReportLayouts {
                                id
                                isDefault
                            }
                        }
                    }
                `,
            });

            const defaultId = summaryReportLayouts.find(({ isDefault }) => isDefault)?.id ?? summaryReportLayouts[0]?.id;
            if (defaultId) {
                return redirect(getId(defaultId));
            }

            throw new Response('Not Found', { status: 404 });
        },

    }, {
        path: ':layoutId',
        children: [{
            index: true,
            componentLoader: () => import(/* webpackChunkName: "components/pages/SummaryReportPage" */'components/pages/SummaryReportPage'),
            loader: async ({ params: { layoutId } }) => {
                const { data } = await apolloClient.query({
                    query: require('queries/SummaryReportPage.graphql'),
                    variables: { id: btoa(`SummaryReportLayout:${layoutId}`) },
                });
                return data;
            },
            handle: {
                crumb: () => ignoreTopLevelTitle ? [] : [{
                    title: Locale.getSection('components.routes').lmsg('summaryReport'),
                }],
                helpContext: ({ data: { viewer: type } }) => type === 'ADMIN' ? 'admin.report' : 'reseller.report',
            },
        }],
    },
    ],
});
