// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import {
    Form as BaseForm,
    FormProps as BaseFormProps,
    setIn,
    getIn,
    StatusMessage,
} from '@plesk/ui-library';
import { isApolloError, FetchResult } from '@apollo/client';
import { forwardRef, useState, useEffect, useMemo, useImperativeHandle, useRef, ForwardedRef, ReactElement } from 'react';
import { redirect, redirectPost, api, showInternalError, escapeHtml, render } from 'jsw';
import { toFormData } from 'helpers/form';
import JswComponent from './jsw/JswComponent';
import { useNavigate } from 'react-router-dom';
import { isClientSideRedirectAllowed } from 'routes';

type EmbeddedForm = {
    name: string;
    content?: string;
    title: string;
};

type StatusMessage = {
    status: string;
    title?: string;
    content: string;
};

type BaseMutation <FV> = (payload: { variables: { input: NoInfer<FV> } }) => Promise<FetchResult>;

const clearMessages = (root: HTMLElement) => {
    root.querySelectorAll<HTMLElement>('.field-errors').forEach(errors => {
        errors.style.display = 'none';
        errors.closest('.form-row')?.classList.remove('error');
        errors.querySelectorAll('.error-hint').forEach(error => {
            error.parentNode!.removeChild(error);
        });
    });
};

const processFieldMessage = (
    root: HTMLElement,
    prefix: string[],
    _name: string,
    message: string,
) => {
    let fieldErrors;
    const formElement: HTMLElement = root.querySelector(`#${prefix.join('-')}`)!;
    fieldErrors = formElement ? formElement.parentNode!.querySelector('.field-errors') : null;
    if (!fieldErrors) {
        fieldErrors = formElement ? formElement.closest('.form-row')!.querySelector('.field-errors') : null;
    }
    if (!fieldErrors) {
        fieldErrors = root.querySelector(`#${prefix.join('-')}-form-row`)!.querySelectorAll('.field-errors');
        fieldErrors = fieldErrors[fieldErrors.length - 1];
    }

    fieldErrors.closest('.form-row')!.classList.add('error');
    render(fieldErrors, `<span class="error-hint">${escapeHtml(message)}</span>`);
    (fieldErrors as HTMLElement).style.display = '';
};

const processFieldMessages = (
    root: HTMLElement,
    messages: Record<string, string> | Record<string, string>[],
    prefix: string[],
) => {
    if (Array.isArray(messages)) {
        messages.forEach(message => {
            if (typeof message === 'string') {
                processFieldMessage(root, prefix, 'error', message);
            } else {
                // @ts-expect-error unknown problem
                prefix.push(name);
                processFieldMessages(root, message, prefix);
                prefix.pop();
            }
        });
    } else {
        Object.entries(messages).forEach(([key, value]) => {
            if (typeof value === 'string') {
                processFieldMessage(root, prefix, key, value);
            } else {
                prefix.push(key);
                processFieldMessages(root, value, prefix);
                prefix.pop();
            }
        });
    }
};

const findSubFormFields = <FV extends Record<string, unknown>> (
    formId: string | undefined,
    subFormPrefix: string[],
    callback?: (key: string, value: FV[string]) => void,
) => {
    if (subFormPrefix.length === 0 || typeof callback !== 'function') {
        return;
    }
    const formData = new FormData([...document.forms].find(({ id }) => id === formId));
    for (const entry of formData.entries()) {
        const [key, value] = entry;
        if (subFormPrefix.some(prefix => key.startsWith(prefix))) {
            callback(key, value as FV[string]);
        }
    }
};

const getSubFormPrefixes = (formPrefix: string, embeddedForms?: EmbeddedForm[]) => (embeddedForms || []).reduce((acc, { name }) => [
    ...acc,
    `${formPrefix}[${name}]`,
    name,
], [] as string[]);

const setElementValue = (name: string, value: string) => {
    const elements = document.getElementsByName(name) as NodeListOf<HTMLInputElement>;
    elements.forEach(element => {
        if (element.type === 'checkbox' || element.type === 'radio') {
            element.checked = element.value === value;
        } else if (element.type === 'hidden' &&
            Array.prototype.filter.call(elements, ({ type }) => type === 'checkbox').length > 0) {
            // set only checkbox state
        } else {
            element.value = value;
        }
    });
};

type OnSuccess <
    FV extends Record<string, unknown>,
    Mutation,
    Result = Mutation extends BaseMutation<FV>
        /** @deprecated use mutation callbacks instead */
        ? (data: NonNullable<Mutation extends BaseMutation<FV> ? Awaited<ReturnType<Mutation>>['data'] : void>, isApply?: boolean) => void
        : () => void,
> = Result;

/* eslint-disable react/require-default-props */
export type FormProps <
    FV extends Record<string, unknown>,
    Mutation = undefined,
> = {
    mutation?: Mutation;
    formPrefix?: string;
    embeddedFormsRender?: (children: ReactElement[]) => ReactElement;
    embeddedForms?: EmbeddedForm[];
    statusMessages?: StatusMessage[];
    onSubmit?: (values: FV, isApply?: boolean) => Promise<FV | null | void> | FV | null | void;
    onError?: (errors: Record<string, unknown>, setErrors?: (errors: Record<string, unknown>) => void) => void;
    onSuccess?: OnSuccess<FV, Mutation>;
    // TODO: cover in PPP-67379
    onLongTask?: unknown;
} & BaseFormProps<FV>;

type FormComponent = <
    FV extends Record<string, unknown>,
    Mutation = undefined,
    // TODO: cover ref in PPP-67379
> (p: FormProps<FV, Mutation> & { ref?: ForwardedRef<unknown> }) => ReactElement;

const Form = forwardRef(<
    FV extends Record<string, unknown>,
    Mutation extends BaseMutation<FV> | undefined = undefined,
>({
        children,
        id,
        action,
        mutation,
        values,
        onFieldChange,
        onSubmit,
        onError,
        onSuccess,
        onLongTask,
        embeddedForms,
        formPrefix = '',
        embeddedFormsRender,
        statusMessages: messages,
        errors: defaultErrors,
        ...props
    }: FormProps<FV, Mutation>, ref: ForwardedRef<unknown>) => {
    const navigate = useNavigate();
    const innerRef = useRef<HTMLFormElement>(null);
    const statusRef = useRef<HTMLSpanElement>(null);
    const [errors, setErrors] = useState<Record<string, unknown>>({});
    const [statusMessages, setStatusMessages] = useState<StatusMessage[]>(messages ?? []);
    const [state, setState] = useState<BaseFormProps<Record<string, unknown>>['state'] | null>(null);

    const formErrors = useMemo(() => ({ ...errors, ...(defaultErrors || {}) }), [errors, defaultErrors]);

    useEffect(() => {
        (embeddedForms || []).forEach(({ name }) => {
            const form = document.getElementById(`embedded-form-${name}`)!;
            clearMessages(form);

            // @ts-expect-error // TODO: cover in PPP-67379
            const subFormErrors = (formPrefix ? errors[formPrefix] || {} : errors)[name] || {};
            processFieldMessages(form, subFormErrors, formPrefix ? [formPrefix, name] : [name]);
        });
    }, [errors, embeddedForms, formPrefix]);

    useEffect(() => {
        findSubFormFields(id, getSubFormPrefixes(formPrefix, embeddedForms), (key, value) => {
            const previousValue = getIn(values as FV, key, value);
            setElementValue(key, previousValue);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [embeddedForms]);

    useImperativeHandle(ref, () => ({
        handleEmbeddedFormValues() {
            findSubFormFields(id, getSubFormPrefixes(formPrefix, embeddedForms), onFieldChange);
        },
        submit() {
            innerRef.current?.submit();
        },
    }), [id, onFieldChange, embeddedForms, formPrefix, innerRef]);

    useEffect(() => {
        if (statusMessages.length) {
            statusRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [statusMessages]);

    const handleSubmit = async (values: FV, isApply?: boolean) => {
        findSubFormFields(id, getSubFormPrefixes(formPrefix, embeddedForms), (key, value) => {
            values = setIn(values, key, value);
        });
        if (typeof onSubmit === 'function') {
            // eslint-disable-next-line require-atomic-updates
            (values as FV | null | void) = await onSubmit(values, isApply);
        }
        if (!values) {
            return;
        }

        setStatusMessages([]);
        setErrors({});

        const formState = isApply ? 'apply' : 'submit';
        setState(formState);

        if (mutation) {
            try {
                const { data } = await mutation({ variables: { input: values } });
                if (typeof onSuccess === 'function') {
                    onSuccess(data!, isApply);
                }
            } catch (error) {
                if (!(error instanceof Error)) {
                    throw error;
                }

                if (!isApolloError(error) || !error.graphQLErrors.length) {
                    setStatusMessages([{ status: 'error', content: error.message }]);
                    return;
                }

                const { graphQLErrors } = error;

                if (graphQLErrors[0].extensions?.category === 'validate') {
                    const errors = graphQLErrors[0].extensions.messages;
                    setErrors(errors);
                    if (typeof onError === 'function') {
                        onError(errors);
                    }
                } else {
                    setStatusMessages([{
                        status: 'error',
                        content: graphQLErrors[0].extensions?.debugMessage || graphQLErrors[0].message,
                    }]);
                }
            } finally {
                setState(null);
            }
            return;
        }

        try {
            handleSubmitSuccess(await api.post(action || window.location.href, toFormData(values)), formState);
        } catch (e) {
            setState(null);
            showInternalError(e);
        }
    };

    // TODO: cover response in PPP-67379
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSubmitSuccess = (response: any, formState: 'apply' | 'submit') => {
        if (response.componentType === 'Jsw.Task.ProgressBar.Item' && typeof onLongTask === 'function') {
            onLongTask(response);
            return;
        }
        const isApply = formState === 'apply';

        const { status, redirect: url, forceRedirect = false, postData, target, formMessages, statusMessages = [] } = response;
        if (url) {
            if (typeof onSuccess === 'function') {
                // @ts-expect-error TODO: cover in PPP-67379
                onSuccess();
            }

            if (isApply) {
                const { pathname } = window.location;
                if (isClientSideRedirectAllowed(pathname)) {
                    navigate(pathname, { replace: true });
                    setState(null);
                } else {
                    document.location.reload();
                }
            } else if (postData) {
                redirectPost(url, postData, target);
            } else {
                (isClientSideRedirectAllowed(url) && !forceRedirect) ? navigate(url) : redirect(url, undefined, target);
            }
        } else {
            setState(null);
            setStatusMessages(statusMessages);
            setErrors(formMessages);
            if (!formMessages && status !== 'error' && typeof onSuccess === 'function') {
                onSuccess(response, isApply);
            }
            if (formMessages && typeof onError === 'function') {
                onError(formMessages, setErrors);
            }
        }
    };

    const renderStatusMessages = () => {
        if (!statusMessages.length) {
            return null;
        }
        return (
            <span ref={statusRef}>
                {statusMessages.map(({ status, content, title }) => (
                    <StatusMessage intent={status === 'error' ? 'danger' : 'success'} key={content}>
                        {title ? <><b>{title}{':'}</b>{' '}</> : null}
                        <span
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: content }}
                        />
                    </StatusMessage>
                ))}
            </span>
        );
    };

    const renderEmbeddedForms = () => (embeddedForms || []).map(({ name, content }) => (
        <JswComponent
            key={name}
            id={`embedded-form-${name}`}
        >
            {/** @ts-expect-error JswComponents is js */}
            {content}
        </JswComponent>
    ));

    return (
        <BaseForm
            {...props}
            ref={innerRef}
            id={id}
            values={values}
            onFieldChange={onFieldChange}
            onSubmit={handleSubmit}
            errors={formErrors}
            state={state ?? undefined}
        >
            {renderStatusMessages()}
            {children}
            {embeddedFormsRender ? embeddedFormsRender(renderEmbeddedForms()) : renderEmbeddedForms()}
        </BaseForm>
    );
});

Form.displayName = 'Form';

export default Form as FormComponent;
