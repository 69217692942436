// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import Cookie from 'common/cookie';
import { Component, getComponent } from '../component';
import { Tooltip } from '../tooltip';
import { bar } from '../bar';
import { SmallTools } from '../small-tools';
import { messageBox } from '../message-box';
import { DropdownManager } from '../dropdown-manager';
import createComponent from '../createComponent';
import addStatusMessage from '../addStatusMessage';
import clearStatusMessages from '../clearStatusMessages';
import showInternalError from '../showInternalError';
import prepareUrl from '../prepareUrl';
import addUrlParams from '../addUrlParams';
import render from '../render';
import createElement from '../createElement';
import escapeHtml from '../escapeHtml';
import emptyFn from '../emptyFn';
import { redirect } from '../form-redirect';
import Filters from './Filters';
import ListContextMenu from './ListContextMenu';
import { COLUMN_ACTIONS, COLUMN_SELECTION, ITEMS_UNLIMITED } from './constants';
import stripTags from 'helpers/stripTags';
import api from '../api';

import './List.less';

/**
 * The class for the implementation of the widget "List". In addition to displaying the data,
 * it is possible to change the sorting, search, perform group operations.
 */
class List extends Component {
    /**
     * @cfg {String} listCls=list
     */
    /**
     * @cfg {String} dataUrl=""
     */
    /**
     * @cfg {Object[]} operations List operations.
     * See SmallTools.operations  for details.
     */
    /**
     * @cfg {Object[]} columns
     */
    /**
     * @cfg {Object[]} itemActions
     */

    _initConfiguration(config) {
        super._initConfiguration(config);

        this._cls += ' js-list';
        this._listCls = this._getConfigParam('listCls', 'list');
        this._tableId = `${this._id}-table`;
        this._dataUrl = this._getConfigParam('dataUrl', '');
        this._operations = this._getConfigParam('operations', null);
        this._itemActions = this._getConfigParam('itemActions', {});
        this._columns = this._getConfigParam('columns', []).filter(Boolean);
        this._limitValues = this._getConfigParam('limitValues', [10, 25, 100, ITEMS_UNLIMITED]);
        this._splitListData(this._getConfigParam('data', { data: null, pathbar: null, state: null, pager: null, locale: null, additional: null }));
        this._searchFilters = $H(this._getConfigParam('searchFilters', {}));
        this._searchOveral = this._getConfigParam('searchOveral', null);
        this._pageable = this._getConfigParam('pageable', true);
        /** @deprecated use renderDisabledSelectHint instead */
        this._disabledSelectHint = this._getConfigParam('disabledSelectHint', '');
        this._renderDisabledSelectHint = this._getConfigParam('renderDisabledSelectHint', null);
        this._clearStatusOnReload = this._getConfigParam('clearStatusOnReload', true);
        this.isDisabledItem = this._getConfigParam('isDisabledItem', () => false);
        this._onRedraw = this._getConfigParam('onRedraw', emptyFn);
        this._emptyViewMode = this._getConfigParam('emptyViewMode', 'items');
        this._getTotalInfo = this._getConfigParam('getTotalInfo', function () {
            return this.getLocale().formatMessage('recordsTotal', { total: this._pager.totalItemCount });
        });
        this._getRowClass = this._getConfigParam('getRowClass', function () {
            return '';
        });

        if (Array.isArray(this._operations)) {
            this._operations = this._operations.filter(value => value !== null);
            if (!this._operations.length) {
                this._operations = null;
            }
        }
    }

    _getSearchOveralField() {
        if (null === this._searchOveral) {
            this._searchFilters.each(function (pair) {
                if (pair.value.overal) {
                    this._searchOveral = pair.key;
                }
            }, this);
            if (null === this._searchOveral && 1 === this._searchFilters.keys().length) {
                this._searchOveral = this._searchFilters.keys()[0];
            }
            if (null === this._searchOveral) {
                this._searchOveral = false;
            }
        }

        return this._searchOveral;
    }

    _initComponentElement() {
        super._initComponentElement();

        this._updateComponentElement(`<div id="${this._id}-container"></div>`);
        this._initDisablerOverlay();
    }

    _splitListData(listData, place) {
        if (!listData || !listData.data) {
            return false;
        }

        const dataLen = listData.data.length;
        if ('before' === place) {
            this._data = listData.data.concat(this._data);
        } else if ('after' === place) {
            this._data = this._data.concat(listData.data);
        } else {
            this._data = listData.data;
        }
        this._state = listData.state || {};
        this._pager = listData.pager || {};
        if (listData.locale) {
            this._locale.messages = { ...this._locale.messages, ...listData.locale };
        }
        if (listData.pathbar) {
            this._pathbar = listData.pathbar;
        }
        if (listData.additional) {
            this._additionalData = { ...this._additionalData, ...listData.additional };
        }
        return dataLen;
    }

    _isColumnSelectionPresent() {
        return this._getColumns().includes(COLUMN_SELECTION);
    }

    /**
     * @param {String} dataIndex
     */
    onHeaderClick(dataIndex) {
        this._state.sortField = dataIndex;
        this._state.sortDirection = ('down' === this._state.sortDirection)
            ? 'up'
            : 'down';

        this.reload(`/sort-field/${dataIndex}/sort-dir/${this._state.sortDirection}`);
    }

    /**
     * @param {Boolean} [force]
     */
    updateFilter(force) {
        if (!document.getElementById(this._tableId)) {
            this.checkEmptyList();
            return;
        }
        let filterChanged = false;
        let hasFilter = false;
        this._getColumns()
            .filter(({ isVisible }) => 'function' !== typeof isVisible || isVisible())
            .forEach(column => {
                if (!column.filter || !column.filter.type) {
                    return;
                }

                let input;
                if (column.filter.options) {
                    if (column.filter.multi) {
                        input = this._componentElement.querySelector(`th[data-index="${column.dataIndex}"]`).querySelectorAll('input[type=checkbox]:checked');
                    } else {
                        input = this._componentElement.querySelector(`th[data-index="${column.dataIndex}"] select`);
                    }
                } else {
                    input = this._componentElement.querySelector(`th[data-index="${column.dataIndex}"] input`);
                }
                if (!input) {
                    column.filter.value = '';
                    return;
                }

                hasFilter = true;

                let value;
                if (column.filter.multi) {
                    value = [];
                    input.forEach(function (el) {
                        value.push(el.value.toLowerCase());
                    });
                    if (value.length === column.filter.options.length) {
                        value = [];
                    }
                } else {
                    value = input.value.toLowerCase();
                }
                if (value === column.filter.value) {
                    return;
                }

                column.filter.value = value;
                filterChanged = true;
            });

        if (!hasFilter || (!force && !filterChanged)) {
            this.checkEmptyList();
            return;
        }
        let oddEvenRowClass = 'even';
        document.getElementById(this._tableId).querySelectorAll('tr.odd, tr.even').forEach((row, rowIndex) => {
            const cells = row.childNodes;
            let match = true;

            this._getColumns()
                .filter(({ isVisible }) => 'function' !== typeof isVisible || isVisible())
                .forEach((column, columnIndex) => {
                    const value = this._data[rowIndex][column.dataIndex];
                    if (cells[columnIndex]._filtered) {
                        if (column.noEscape) {
                            cells[columnIndex].innerHTML = value;
                        } else {
                            cells[columnIndex].innerHTML = escapeHtml(value);
                        }
                        delete cells[columnIndex]._filtered;
                    }

                    if (!match || !column.filter || !column.filter.type || 0 === column.filter.value.length) {
                        return;
                    }

                    if (column.filter.type === 'date') {
                        if (new Date(value.replace(/ /g, 'T')) < new Date(column.filter.value.replace(/ /g, 'T'))) {
                            match = false;
                        }
                        return;
                    }

                    if (column.filter.options) {
                        if (column.filter.multi) {
                            if (column.filter.value.indexOf(value.toLowerCase()) === -1) {
                                match = false;
                            }
                        } else if (value.toLowerCase() !== column.filter.value) {
                            match = false;
                        }
                        return;
                    }

                    const pos = value.toLowerCase().indexOf(column.filter.value);

                    if (pos === -1 || (column.filter.startsWith && pos !== 0)) {
                        match = false;
                        return;
                    }

                    if (column.noEscape) {
                        cells[columnIndex].innerHTML = `${value.substr(0, pos)
                        }<b class="search-result-label">${
                            value.substr(pos, column.filter.value.length)
                        }</b>${
                            value.substr(pos + column.filter.value.length)}`;
                    } else {
                        cells[columnIndex].innerHTML = `${escapeHtml(value.substr(0, pos))
                        }<b class="search-result-label">${
                            escapeHtml(value.substr(pos, column.filter.value.length))
                        }</b>${
                            escapeHtml(value.substr(pos + column.filter.value.length))}`;
                    }
                    cells[columnIndex]._filtered = true;
                });

            if (match) {
                row.classList.remove(oddEvenRowClass);
                oddEvenRowClass = oddEvenRowClass === 'odd' ? 'even' : 'odd';
                row.classList.add(oddEvenRowClass);
                row.style.display = '';
            } else {
                row.style.display = 'none';
            }
        });
        this.checkEmptyList();
    }

    /**
     * @param {String} value
     */
    onLimitClick(value) {
        this.reload({ 'items-per-page': value });
    }

    /**
     * @param {String} page
     */
    onPagerClick(page) {
        this.reload({ page });
    }

    handleSelectButtonClick = () => {
        const globalCheckbox = this._componentElement.querySelector('input[name="listGlobalCheckbox"]');
        globalCheckbox.checked = !globalCheckbox.checked;
        this.onListGlobalCheckboxClick();
    };

    onListGlobalCheckboxClick() {
        const actionsBox = this._getActionBoxElement();
        const globalCheckbox = this._componentElement.querySelector('input[name="listGlobalCheckbox"]');

        if (actionsBox) {
            const selectButton = actionsBox.querySelector('.r-actions-select');
            if (selectButton) {
                if (globalCheckbox.checked) {
                    selectButton.classList.add('r-actions-item-active');
                } else {
                    selectButton.classList.remove('r-actions-item-active');
                }
            }
        }

        this._componentElement.querySelectorAll('input[name="listCheckbox[]"]').forEach(checkbox => {
            if (globalCheckbox.checked) {
                checkbox.closest('tr').classList.add('selected');
            } else {
                checkbox.closest('tr').classList.remove('selected');
            }
            checkbox.checked = globalCheckbox.checked;
        });
    }

    onPagerButtonClick(event) {
        const paging = this._componentElement.querySelector('.paging');
        const show = !paging.classList.contains('r-paging-visible');
        if (this._actionBox) {
            this._actionBox._hideResponsiveButtonBlocks();
        }
        if (show) {
            paging.classList.add('r-paging-visible');
        }
        event.stopPropagation();
    }

    listHeadersView() {
        const headers = [];
        const filters = [];
        let hasFilter = false;
        let colspan = 0;

        this._getColumns().forEach(column => {
            if (colspan) {
                colspan--;
                return;
            }

            if ('function' === typeof column.isVisible) {
                if (!column.isVisible()) {
                    return;
                }
            }

            if (column.headerColspan) {
                colspan = column.headerColspan;
            }

            let { header } = column;

            let headerCls = column.headerCls ? `.${column.headerCls}` : '';

            if ('undefined' !== typeof column.headerRenderer) {
                header = column.headerRenderer(column);
            } else {
                let sortCssClass = '';
                if (this._state.sortField === column.dataIndex) {
                    sortCssClass = 'down' === this._state.sortDirection ? '' : '.sort-up';
                    headerCls += '.sort';
                }

                if (column.sortable) {
                    header = createElement(`a${sortCssClass}`, { onclick: this.onHeaderClick.bind(this, column.dataIndex) }, header);
                }
            }
            headers.push(createElement(`th${headerCls}${colspan ? `[colspan="${colspan}"]` : ''}`, { ...column.headerParams }, header));

            let filter = '';
            if (column.filter) {
                hasFilter = true;
                filter = Filters.create({
                    name: column.dataIndex,
                    locale: this.getLocale(),
                    ...column.filter,
                }, this.updateFilter.bind(this, false));
            }
            filters.push(createElement('th', { 'data-index': column.dataIndex }, filter));
        });

        return createElement('thead',
            hasFilter ? createElement('tr.list-search-filter', filters) : '',
            createElement('tr', headers),
        );
    }

    listDataView(listData = this._data, indexOffset = 0) {
        if (!listData) {
            return null;
        }

        return createElement('tbody',
            Object.keys(listData).map(index => this.rowView(listData[index], index, indexOffset)),
        );
    }

    rowView(item, index, indexOffset) {
        const columns = this._getColumns()
            .filter(column => 'function' !== typeof column.isVisible || column.isVisible())
            .map(column => {
                let cell = null;

                if (typeof column.renderer === 'function') {
                    const isDisabled = this.isDisabledItem(item);

                    if (!isDisabled || !column.hideDisabled) {
                        cell = column.renderer.call(this, item, isDisabled);
                    }
                } else if ('undefined' !== typeof column.dataIndex && 'undefined' !== typeof item[column.dataIndex]) {
                    if (column.noEscape) {
                        cell = item[column.dataIndex];
                    } else {
                        cell = escapeHtml(item[column.dataIndex]);
                    }
                }

                return createElement('td', {
                    class: column.cls,
                }, cell);
            });

        return createElement(`tr.${this._getRowClass(item)}.${(index + indexOffset) % 2 ? 'even' : 'odd'}`, {
            'data-row-id': item.id,
        }, columns);
    }

    listView() {
        return createElement(`.${this._listCls}`,
            createElement(`table#${this._tableId}[width="100%"][cellspacing="0"]`,
                this.listHeadersView(),
                this.listDataView(),
            ),
        );
    }

    containerView() {
        return createElement(`#${this._id}-container`,
            this._getActionBox(),
            this._getPathbarHtml(),
            this._getPagerHtml(),
            this.listView(),
            this._getPagerHtml(),
            this.emptyViewContainer(),
        );
    }

    checkEmptyList() {
        const isEmpty = this.isListEmpty();
        this._componentElement.querySelectorAll('.js-empty-list').forEach(element => {
            element.style.display = isEmpty ? '' : 'none';
        });
        this._componentElement.querySelectorAll('.paging').forEach(element => {
            element.style.display = isEmpty ? 'none' : '';
        });
        const listSelector = this._listCls.split(' ').reduce((previousValue, currentValue) => {
            if (currentValue !== '') {
                previousValue += `.${currentValue}`;
            }
            return previousValue;
        }, '');
        this._componentElement.querySelectorAll(listSelector).forEach(element => {
            element.style.display = isEmpty ? 'none' : '';
        });
    }

    isFiltered() {
        return Object.values(this._state?.searchFilters || {}).some(({ searchText }) => searchText);
    }

    isListEmpty() {
        if (!document.getElementById(this._tableId)) {
            return !this._data.length;
        }
        return ![...document.getElementById(this._tableId).querySelectorAll('tr.odd, tr.even')].filter(element => element.style.display !== 'none').length;
    }

    _getActionBox() {
        if (!this._operations && !this._searchFilters.keys().length) {
            return null;
        }

        if (!this.isFiltered() && this.isListEmpty() && this._emptyViewMode === 'all') {
            return null;
        }

        if (this._state.forceShowSearch) {
            Cookie.set(`${this._id}-search-show`, 'true');
        }

        this._actionBox = new SmallTools({
            locale: this.getLocale(),
            operationsId: `${this._id}-operations`,
            operations: this._getOperations(),
            listId: this._id,
            searchFilters: this._searchFilters,
            searchFiltersState: this._state.searchFilters,
            searchOveral: this._searchOveral,
            searchHandler: this._onSearchClick.bind(this),
            resetSearchHandler: this._onResetSearchClick.bind(this),
            searchCollapsed: 'true' !== Cookie.get(`${this._id}-search-show`),
            onToggleSearch: collapsed => {
                Cookie.set(`${this._id}-search-show`, !collapsed);
                this._hideItemsNotSelectedWarning();
            },
            quickSearchInputId: `${this._id}-search-text-${this._getSearchOveralField()}`,
        });

        return this._actionBox;
    }

    _getActionBoxElement() {
        return this._componentElement.querySelector('.actions-box');
    }

    _getPathbarHtml() {
        if (!this._pathbar) {
            return '';
        }

        return `<div id="${this._id}-pathbar"></div>`;
    }

    _getPagerHtml() {
        return this._pageable ? (
            createElement('.paging',
                createElement('.paging-area',
                    createElement('span.paging-info', { onclick: e => this.onPagerButtonClick(e) }, this._getTotalInfo()),
                    createElement('span.paging-view', this.lmsg('numberOfItemPerPage'), ': ', this._getLimitValuesHtml()),
                    this._pager.pageCount > 1 ? createElement('span.paging-nav', this.lmsg('pages'), ': ', this._getPagesItemsHtml()) : null,
                ),
            )
        ) : null;
    }

    _getLimitValuesHtml() {
        const result = [];
        this._limitValues.forEach((limitValue, index) => {
            const limitValueTitle = (ITEMS_UNLIMITED === limitValue)
                ? this.lmsg('allItems')
                : limitValue;

            if (index > 0) {
                result.push(' ');
            }
            result.push(this._pager.itemCountPerPage !== limitValue ? (
                createElement('a', { onclick: () => this.onLimitClick(limitValue) }, limitValueTitle)
            ) : (
                createElement('span', limitValueTitle)
            ));
        });
        return result;
    }

    _getPagesItemsHtml() {
        const result = [];

        if (this._pager.first !== this._pager.current) {
            result.push(createElement('a', { onclick: () => this.onPagerClick(this._pager.first) }, this.lmsg('firstPage')));
        } else {
            result.push(createElement('span', this.lmsg('firstPage')));
        }
        result.push(' ');

        if (this._pager.previous) {
            result.push(createElement('a', { onclick: () => this.onPagerClick(this._pager.previous) }, '&lt;&lt;'));
        } else {
            result.push(createElement('span', '&lt;&lt;'));
        }
        result.push(' ');

        $H(this._pager.pagesInRange).values().each(page => {
            if (page !== this._pager.current) {
                result.push(createElement('a', { onclick: () => this.onPagerClick(page) }, page));
            } else {
                result.push(page);
            }
            result.push(' ');
        });

        if (this._pager.next) {
            result.push(createElement('a', { onclick: () => this.onPagerClick(this._pager.next) }, '&gt;&gt;'));
        } else {
            result.push(createElement('span', '&gt;&gt;'));
        }
        result.push(' ');

        if (this._pager.last !== this._pager.current) {
            result.push(createElement('a', { onclick: () => this.onPagerClick(this._pager.last) }, this.lmsg('lastPage')));
        } else {
            result.push(createElement('span', this.lmsg('lastPage')));
        }

        return result;
    }

    emptyViewContainer() {
        return createElement('.js-empty-list.empty-list',
            this.isFiltered() ? createElement('p.text-muted', this.lmsg('noEntriesFound')) : this.emptyView(),
        );
    }

    emptyView() {
        return this._getConfigParam('emptyView', createElement('p.text-muted', this.lmsg('noEntries')));
    }

    _onSearchClick(form) {
        this.reload(`?${Form.serialize(form)}`);
    }

    _onResetSearchClick() {
        this.reload('/reset-search/true/');
    }

    _addSelectionHandlers() {
        let selectButton;

        const rActionsList = this._componentElement.querySelector('.actions-box .r-actions-list');
        if (rActionsList) {
            selectButton = new Element('li', { class: 'r-actions-item r-actions-select' });
            selectButton.innerHTML = '<span></span>';
            selectButton.addEventListener('click', event => {
                event.stopPropagation();
                this.handleSelectButtonClick();
            });
            render(rActionsList, selectButton, 'top');
        }

        const globalCheckbox = this._componentElement.querySelector('input[name="listGlobalCheckbox"]');
        globalCheckbox.addEventListener('click', this.onListGlobalCheckboxClick.bind(this));

        this._componentElement.querySelectorAll('input[name="listCheckbox[]"]').forEach(checkbox => {
            checkbox.addEventListener('click', () => {
                if (checkbox.checked) {
                    checkbox.closest('tr').classList.add('selected');
                } else {
                    checkbox.closest('tr').classList.remove('selected');
                    globalCheckbox.checked = false;
                    if (selectButton) {
                        selectButton.classList.remove('r-actions-item-active');
                    }
                }
            });
        });

        document.body.addEventListener('click', event => {
            if (event.target.closest('.actions-box')) {
                return;
            }

            this._hideItemsNotSelectedWarning();
        });
        document.body.addEventListener('touchstart', () => {
            this._hideItemsNotSelectedWarning();
        });
    }

    _addPathbar() {
        if (!this._pathbar) {
            return;
        }

        render(document.getElementById(`${this._id}-pathbar`), createComponent(this._pathbar));
    }

    disable() {
        this._disablerOverlay.style.display = '';
    }

    enable() {
        this._disablerOverlay.style.display = 'none';
    }

    getSelectedItemsIds() {
        return [...this._componentElement.querySelectorAll('input[name="listCheckbox[]"]')]
            .filter(({ checked }) => checked)
            .map(({ value }) => value);
    }

    getSelectedItems() {
        const ids = [];
        const selectedItems = [];

        this.getSelectedItemsIds().forEach(function (id) {
            ids.push(id);
        });

        this._data.forEach(function (dataItem) {
            const itemIndex = ids.indexOf(dataItem.id.toString());
            if (itemIndex < 0) {
                return;
            }

            selectedItems.push(dataItem);
        });

        return selectedItems;
    }

    checkNonEmptySelection() {
        if (!this.getSelectedItemsIds().length) {
            this._showItemsNotSelectedWarning();
            return false;
        }
        this._hideItemsNotSelectedWarning();
        return true;
    }

    execLongGroupOperation(params, event) {
        let itemId;
        params.onSuccess = function () {
            const progressBar = getComponent('asyncProgressBarWrapper');
            progressBar.removePreparingItem(itemId);
            progressBar.update();
        };

        params.beforeSendRequest = function (sendRequest) {
            const beginOffset = Element.cumulativeOffset(event.target);
            itemId = getComponent('asyncProgressBarWrapper').fly(beginOffset,
                params.taskName,
                function () {
                    sendRequest();
                });
        };

        this.execGroupOperation(params);
    }

    execGroupOperation(params) {
        const payload = params.payload || {};
        // workaround for $A(payload) called in extensions
        Object.defineProperty(payload, 'toArray', {
            value() {
                return Object.entries(this).map(([key, value]) => ({ key, value }));
            },
        });

        const idsParamName = params.submitVarName
            ? params.submitVarName
            : 'ids';

        const { toJsonPayload } = params;
        const selectedItems = params.selectedItems ? params.selectedItems : this.getSelectedItemsIds();
        selectedItems.forEach((id, index) => {
            if (toJsonPayload) {
                payload[idsParamName] ||= [];
                payload[idsParamName].push(id);
            } else {
                payload[`${idsParamName}[${index}]`] = id;
            }
        });

        if (params.checkSelection) {
            if (!params.checkSelection()) {
                return;
            }
        } else if (!selectedItems.length) {
            this._showItemsNotSelectedWarning();
            return;
        } else {
            this._hideItemsNotSelectedWarning();
        }

        const submit = this._submit;
        const sendRequestHandler = params.submitHandler
            ? params.submitHandler
            : (url, payload) => {
                const parameters = {
                    method: 'post',
                    parameters: payload,
                    reloading: false,
                    context: this,

                    onSuccess: transport => {
                        let response;
                        try {
                            response = JSON.parse(transport.responseText);
                        } catch {
                            showInternalError(transport.responseText);
                            return;
                        }
                        if (response.redirect) {
                            redirect(response.redirect);
                        } else if (!response.noReload) {
                            parameters.reloading = true;
                            this.reload();
                        }

                        if (this._clearStatusOnReload) {
                            clearStatusMessages();
                        }
                        response.statusMessages?.forEach(({ status, content }) => {
                            addStatusMessage(status, content);
                        });

                        if (params.onSuccess) {
                            params.onSuccess(response);
                        }
                    },

                    onCreate: () => {
                        this.disable();
                    },

                    onComplete: () => {
                        if (!parameters.reloading) {
                            this.enable();
                        }
                    },

                    onFailure: req => {
                        if (504 === req.status) {
                            addStatusMessage('error', this.lmsg('timeoutMessage'));
                        } else {
                            showInternalError(req.responseText);
                        }

                        this.enable();
                    },
                };

                submit(prepareUrl(url), parameters);
            };

        const sendRequest = function () {
            if (params.beforeSendRequest) {
                params.beforeSendRequest(() => {
                    sendRequestHandler(params.url, payload);
                }, payload);
            } else {
                sendRequestHandler(params.url, payload);
            }
        };

        let { skipConfirmation } = params;
        const { mouseEvent } = params;

        if (mouseEvent && !skipConfirmation) {
            mouseEvent.preventDefault();
            skipConfirmation = mouseEvent.shiftKey;
        }

        if (skipConfirmation) {
            sendRequest();
        } else {
            params.confirmationPopup = params.confirmationPopup || this._confirmationPopup.bind(this, params, sendRequest);
            params.confirmationPopup(payload);
        }
    }

    _submit(url, params) {
        new Ajax.Request(url, params);
    }

    _confirmationPopup({
        getConfirmOnGroupOperation,
        text,
        description,
        subtype,
        needAttention = false,
        needAttentionText = '',
        needAttentionBlockSubmit = false,
        needAttentionOnChange = null,
        requestUrl,
        isAjax,
        loadingTitle,
        locale = {},
    }, onYesClick, requestParams) {
        messageBox.show({
            type: messageBox.TYPE_YESNO,
            buttonTitles: ['Yes', 'No', 'Wait'].reduce((titles, key) => {
                const buttonKey = `button${key}`;
                if (locale[buttonKey]) {
                    titles[key.toLowerCase()] = locale[buttonKey];
                }
                return titles;
            }, {}),
            text: getConfirmOnGroupOperation ? getConfirmOnGroupOperation() : text || locale.confirmOnGroupOperation,
            description,
            subtype,
            needAttention,
            needAttentionText,
            needAttentionBlockSubmit,
            needAttentionOnChange,
            onYesClick,

            isAjax,
            requestUrl,
            requestParams,
            loadingTitle,
        });
    }

    _showItemsNotSelectedWarning() {
        this._hideItemsNotSelectedWarning();
        const element = this._getActionBoxElement()?.querySelector('.actions-msg-container');
        if (element) {
            element.style.display = '';
        } else {
            render(this._getActionBoxElement(), (
                '<div class="actions-msg-container">' +
                     `<span class="list-actions-msg"><span>${this.lmsg('itemsNotSelected')}</span></span>` +
                '</div>'
            ), 'top');
        }
        this._itemsNotSelectedTimerId = setTimeout(() => {
            this._hideItemsNotSelectedWarning();
        }, 3000);
    }

    _hideItemsNotSelectedWarning() {
        clearTimeout(this._itemsNotSelectedTimerId);
        const element = this._getActionBoxElement()?.querySelector('.actions-msg-container');
        if (element) {
            element.style.display = 'none';
        }
    }

    _addEvents() {
        super._addEvents();

        document.body.addEventListener('click', () => {
            const paging = this._componentElement.querySelector('.paging');
            if (paging) {
                paging.classList.remove('r-paging-visible');
            }
        });
    }

    reload(urlParams, place) {
        urlParams = urlParams || '';

        if (!this._dataUrl) {
            return null;
        }

        let reloadUrl = addUrlParams(this._dataUrl, urlParams);

        if (this._additionalData && this._additionalData.controllerName) {
            reloadUrl = addUrlParams(reloadUrl, { controllerName: this._additionalData.controllerName });
        }
        if (this._additionalData && this._additionalData.actionName) {
            reloadUrl = addUrlParams(reloadUrl, { actionName: this._additionalData.actionName });
        }

        if (this._request) {
            this._request.abort();
        }

        this._request = new Ajax.Request(prepareUrl(reloadUrl), {
            method: 'get',
            onSuccess: this._onReloadSuccess.bind(this, place),
            onFailure: this._onReloadFailure.bind(this),
            onException: this._onReloadException.bind(this),
            onCreate: this._onReloadCreate.bind(this),
            onComplete: this._onReloadComplete.bind(this),
        });
        return this._request;
    }

    _onReloadSuccess(place, transport) {
        if (!transport.responseText) {
            // :INFO: sometimes happens in FF if you are go to other page during request
            return;
        }

        let data = {};
        try {
            data = JSON.parse(transport.responseText);
        } catch {
            showInternalError(transport.responseText);
            return;
        }
        if (data.redirect) {
            redirect(data.redirect);
        }
        const dataLen = this._splitListData(data, place);
        if (false === dataLen) {
            this.processReloadError(data);
        } else {
            this.redraw(place, dataLen);
        }
    }

    _onReloadFailure(transport) {
        showInternalError(transport.responseText);
    }

    _onReloadException(transport, exception) {
        showInternalError(`${exception}\n${transport.responseText}`);
    }

    _onReloadCreate() {
        if (this._clearStatusOnReload) {
            clearStatusMessages();
        }
        this.disable();
    }

    _onReloadComplete() {
        this._request = null;
        this.enable();
    }

    _addDisabledCheckboxesHints() {
        const checkboxes = this._componentElement.querySelectorAll('input[type="checkbox"][disabled]');

        checkboxes.forEach(checkbox => {
            new Tooltip.Instance(checkbox.previousSibling, { text: this._disabledSelectHint });
        });
    }

    _renderDisabledCheckboxesHints() {
        if (!this._renderDisabledSelectHint) {
            return;
        }

        this._data.forEach(item => {
            const hint = this._renderDisabledSelectHint(item);

            if (!hint) {
                return;
            }

            const row = document.getElementById(this._tableId).querySelector(`[data-row-id="${item.id}"]`);
            const checkbox = row.querySelector('input[type="checkbox"][disabled]');

            if (!checkbox) {
                return;
            }

            new Tooltip.Instance(checkbox.previousSibling, { text: hint });
        });
    }

    /**
     * @param {String} [place]
     * @param {Number} [dataLen]
     */
    redrawPartial(place, dataLen) {
        if (!dataLen) {
            return;
        }

        if ('before' === place) {
            render(
                document.getElementById(this._tableId).querySelector('thead'),
                this.listDataView(this._data.slice(0, dataLen)),
                'after',
            );
        } else if ('after' === place) {
            render(
                document.getElementById(this._tableId),
                this.listDataView(this._data.slice(-dataLen)),
            );
        }

        Tooltip.initData(this._componentElement);
        this.updateFilter(true);
    }

    /**
     * @param {String} [place]
     * @param {Number} [dataLen]
     */
    redraw(place, dataLen) {
        if (place && document.getElementById(this._tableId)) {
            this.redrawPartial(place, dataLen);
            this._onRedraw();
            return;
        }

        this._componentElement.removeChild(this._componentElement.firstChild);
        render(this._componentElement, this.containerView(), 'top');

        if (this._isColumnSelectionPresent() && this._pager.totalItemCount > 0) {
            this._addSelectionHandlers();
        }
        if (this._contextMenu) {
            this._contextMenu.onRedraw();
        }

        this._addPathbar();

        if (this._disabledSelectHint) {
            this._addDisabledCheckboxesHints();
        }
        this._renderDisabledCheckboxesHints();

        this._addResponsiveHtml();

        this._initDropdowns();
        this._initItemActions();
        Tooltip.initData(this._componentElement);
        this.updateFilter(true);
        this._onRedraw();
    }

    _initItemActions() {
        this._componentElement.querySelectorAll('a[data-action-name]').forEach(link => {
            link.addEventListener('click', event => {
                event.preventDefault();
                if (!this._itemActions[link.dataset.actionName]) {
                    return;
                }

                const itemId = link.closest('tr').dataset.rowId;
                const row = this._data.filter(({ id }) => id === itemId)[0];
                this._itemActions[link.dataset.actionName](row, event);
            });
        });
    }

    _initDropdowns() {
        let dropdown;
        this._dropdowns = this._dropdowns || [];
        while ((dropdown = this._dropdowns.pop())) {
            DropdownManager.unregister(dropdown);
        }

        this._componentElement.querySelectorAll('.dropdown .input-group').forEach(el => {
            this._dropdowns.push(DropdownManager.register(el,
                function () {
                    return el.parentNode.classList.contains('open');
                },
                function () {
                    el.parentNode.classList.add('open');
                },
                function () {
                    el.parentNode.classList.remove('open');
                },
            ));
        });
    }

    /**
     * @param {*} data
     */
    processReloadError(data) {
        if (data && data.statusMessages) {
            data.statusMessages.forEach(({ status, content }) => {
                addStatusMessage(status, content);
            });
        } else {
            showInternalError('Unable to load list data.');
        }
    }

    /**
     * @param {Function} callback
     * @returns {Boolean}
     */
    hasSelectedItems(callback) {
        const selected = this.getSelectedItemsIds();

        if (!selected.length) {
            this._showItemsNotSelectedWarning();
            return false;
        }
        this._hideItemsNotSelectedWarning();

        if (callback) {
            callback.bind(this)();
        }

        return true;
    }

    getItemById(itemId) {
        for (let i = 0; i < this._data.length; i++) {
            if (((typeof this._data[i].id) === 'number' && this._data[i].id === Number(itemId)) ||
                this._data[i].id === itemId
            ) {
                return this._data[i];
            }
        }
        return null;
    }

    getItemByName(itemName) {
        for (let i = 0; i < this._data.length; i++) {
            if (this._data[i].name === itemName) {
                return this._data[i];
            }
        }
        return null;
    }

    _addResponsiveHtml() {
        const columns = this._componentElement.querySelectorAll('thead th');
        this._componentElement.querySelectorAll(`#${this._tableId} tbody tr`).forEach(row => {
            const cells = row.children;
            for (let i = 0; i < cells.length; i++) {
                const element = cells[i];
                const columnName = stripTags(columns[i].innerHTML.trim());
                const columnNameHtml = `<span class="r-visible">${columnName}</span>`;

                this._addResponsiveHtmlToButtons(element, columnNameHtml);

                this._addResponsiveHtmlToImages(element, columnNameHtml);

                this._addResponsiveHtmlToIcons(element, columnNameHtml);

                this._addResponsiveHtmlToNumbers(element, columnName);
            }
        });
    }

    _addResponsiveHtmlToButtons(element, columnNameHtml) {
        const buttons = element.querySelectorAll('a.s-btn');
        buttons.forEach(button => {
            const tooltip = button.parentNode.querySelector('.tooltipData');
            if (!button.querySelector('span').innerHTML && tooltip) {
                button.querySelector('span').innerHTML = `<span class="r-visible">${tooltip.innerHTML}</span>`;
            }
        });
        if (buttons.length === 1 && !buttons[0].querySelector('span').innerHTML) {
            buttons[0].querySelector('span').innerHTML = columnNameHtml;
        }
    }

    _addResponsiveHtmlToImages(element, columnNameHtml) {
        const images = element.querySelectorAll('a img,a [class^="icon-"],a [class*=" icon-"]');
        images.forEach(image => {
            let text = '';
            const tooltip = image.parentNode.parentNode.querySelector('.tooltipData');
            if (!tooltip) {
                if (images.length !== 1) {
                    return;
                }
                text = columnNameHtml;
            } else {
                text = tooltip.innerHTML;
            }
            if (!image.closest('.b-indent-icon') || !image.closest('a').innerHTML.replace(image.closest('.b-indent-icon').outerHTML, '').trim()) {
                let wrapper = image;
                if ('img' === image.tagName.toLowerCase()) {
                    const icon = document.createElement('i');
                    icon.className = 'icon';
                    wrapper.parentNode.replaceChild(icon, wrapper);
                    wrapper = icon;
                    icon.appendChild(image);
                }
                render(wrapper, ` <span class="r-visible">${text}</span>`, 'after');
                image.closest('a').classList.add('i-link');
            }
        });
    }

    _addResponsiveHtmlToIcons(element, columnNameHtml) {
        const icons = element.querySelectorAll('span.b-indent-icon');
        icons.forEach(icon => {
            const tooltip = icon.querySelector('.tooltipData');
            if (tooltip && !icon.parentNode.innerHTML.replace(icon.outerHTML, '').trim()) {
                render(icon.querySelector('img'), ` <span class="r-visible">${tooltip.innerHTML}</span>`, 'after');
            }
        });
        if (icons.length === 1 && !icons[0].querySelector('span.r-visible') && !icons[0].parentNode.innerHTML.replace(icons[0].outerHTML, '').trim()) {
            render(icons[0].querySelector('img'), columnNameHtml, 'after');
        }
    }

    _addResponsiveHtmlToNumbers(element, columnName) {
        const number = element.innerHTML;
        const columnNameHtml = `<span class="r-visible">${columnName}: </span>`;
        if ('-' === number || (!isNaN(parseFloat(number)) && isFinite(number))) {
            render(element, columnNameHtml, 'top');
        }
        const links = element.querySelectorAll('a');
        if (
            links.length === 1 &&
            ('-' === links[0].innerHTML || (!isNaN(parseFloat(links[0].innerHTML)) && isFinite(links[0].innerHTML)))
        ) {
            render(element, columnNameHtml, 'top');
        }
    }

    checkSyncStatus(statusElementId, url, onFinishCallback, randomId, options) {
        // list was reloaded - stop the check of sync status for old list elements
        if (this._randomId !== randomId) {
            return;
        }

        options = {
            timeout: 5000,
            progressStatus: 'started',
            ...options,
        };
        api.get(url)
            .then(result => {
                const statusElement = document.getElementById(statusElementId);
                if (!statusElement) {
                    return;
                }
                if (options.progressStatus === result.status) {
                    setTimeout(() => {
                        this.checkSyncStatus(statusElementId, url, onFinishCallback, randomId, options);
                    }, options.timeout);
                } else {
                    onFinishCallback(statusElement, result);
                }
            });
    }

    _getOperations() {
        const operations = this._operations ? this._operations.slice() : [];
        if (!this._additionalData || !this._additionalData.operations || !this._additionalData.operations.length) {
            return operations;
        }
        const deleteButtonIndex = this._getDeleteButtonIndex();
        let args = [deleteButtonIndex, 0];
        if (deleteButtonIndex !== 0 && operations[deleteButtonIndex - 1].componentType !== 'Jsw.bar.Separator') {
            args.push({ componentType: bar.Separator });
        }
        args = args.concat(this._additionalData.operations);
        if (deleteButtonIndex !== operations.length) {
            args.push({ componentType: bar.Separator });
        }
        operations.splice(...args);
        return operations;
    }

    _getColumns() {
        const columns = this._columns.slice();

        const extensions = (this._additionalData && this._additionalData.extensions) || {};
        Object.keys(extensions).forEach(id => {
            this._modifyColumnsByExtension(columns, extensions[id]);
        });

        return columns;
    }

    _modifyColumnsByExtension(columns, extension) {
        const findColumn = dataIndex => {
            const column = columns.filter(column => column.dataIndex === dataIndex)[0];

            dataIndex = parseInt(dataIndex);
            return column || columns[dataIndex < 0 ? dataIndex + columns.length : dataIndex - 1];
        };

        if (extension.columnsOverride) {
            Object.keys(extension.columnsOverride).forEach(dataIndex => {
                const originalColumn = findColumn(dataIndex);
                if (!originalColumn) {
                    return;
                }

                const column = extension.columnsOverride[dataIndex];

                if (column.title) {
                    column.header = column.title;
                }

                if (column.renderer) {
                    // eslint-disable-next-line no-eval
                    column.renderer = eval(`(${column.renderer})`);
                }

                if ('undefined' !== typeof column.isVisible && !column.isVisible) {
                    column.isVisible = function () {
                        return false;
                    };
                }

                Object.extend(originalColumn, column);
            });
        }

        if (extension.columns) {
            Object.keys(extension.columns).forEach(dataIndex => {
                let column = extension.columns[dataIndex];

                if (column.renderer) {
                    // eslint-disable-next-line no-eval
                    column.renderer = eval(`(${column.renderer})`);
                }

                column = {
                    header: column.title,
                    dataIndex,
                    ...column,
                };

                let index;
                let originalColumn;

                if (column.insertBefore && (originalColumn = findColumn(column.insertBefore))) {
                    index = columns.indexOf(originalColumn);
                } else if (column.insertAfter && (originalColumn = findColumn(column.insertAfter))) {
                    index = columns.indexOf(originalColumn) + 1;
                } else {
                    index = columns.length;
                }

                columns.splice(index, 0, column);
            });
        }
    }

    _getDeleteButtonIndex() {
        if (!this._operations) {
            return 0;
        }
        let deleteButtonIndex = this._operations.length;
        this._operations.forEach((el, i) => {
            if (el.addCls === 'sb-remove-selected') {
                deleteButtonIndex = i;
            }
        });
        return deleteButtonIndex;
    }

    getItemActions({ actions = [] }) {
        actions = actions
            .reduce((actions, actionInfo) => {
                if (actionInfo === 'separator') {
                    if (actions.length && actions[actions.length - 1] !== 'separator') {
                        actions.push(actionInfo);
                    }
                    return actions;
                }

                if (typeof actionInfo === 'string') {
                    actionInfo = { name: actionInfo };
                }
                if (actionInfo.href || this._itemActions[actionInfo.name]) {
                    actions.push(actionInfo);
                }
                return actions;
            }, []);

        if (actions[actions.length - 1] === 'separator') {
            actions.pop();
        }

        return actions;
    }

    render() {
        super.render();

        if (this._getColumns().includes(COLUMN_ACTIONS)) {
            this._contextMenu = new ListContextMenu({
                renderTo: document.body,
                list: this,
            });
        }

        if (!this._data) {
            this.reload();
            return;
        }

        this.redraw();
    }
}

// TODO PMT-4391: Plesk migrator is broken in Plesk 17.9.1 after cutting core's classes
List.subclasses = [];

export default List;
