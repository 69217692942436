// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { Text, Link, Icon } from '@plesk/ui-library';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { UAT, Locale } from 'jsw';

import { makeVar, useReactiveVar } from '@apollo/client';
import { NotificationPropType, NOTIFICATION_CATEGORIES } from './lib/PropTypes';

import './NotificationToast.less';

const Translate = Locale.getTranslate('components.notification-center');

const selectedToastId = makeVar(null);

const useSelectedToastId = () => useReactiveVar(selectedToastId);

const categoryIconMap = {
    [NOTIFICATION_CATEGORIES.INFORMATION]: 'info-circle-filled',
    [NOTIFICATION_CATEGORIES.FEEDBACK]: 'info-circle-filled',
    [NOTIFICATION_CATEGORIES.PROMOTION]: 'info-circle-filled',
    [NOTIFICATION_CATEGORIES.WARNING]: 'triangle-exclamation-mark-filled',
    [NOTIFICATION_CATEGORIES.ALERT]: 'exclamation-mark-circle-filled',
};


const categoryIntentMap = {
    [NOTIFICATION_CATEGORIES.INFORMATION]: 'info',
    [NOTIFICATION_CATEGORIES.FEEDBACK]: 'info',
    [NOTIFICATION_CATEGORIES.PROMOTION]: 'info',
    [NOTIFICATION_CATEGORIES.WARNING]: 'warning',
    [NOTIFICATION_CATEGORIES.ALERT]: 'danger',
};


const NotificationBody = ({ context, subject, onSelectNotification }) => (
    <>
        <Text>{context}</Text>
        <br />
        <Text bold>{subject}</Text>
        <br />
        <Link data-type="notification-toast-details-link" onClick={onSelectNotification}>
            <Translate content="learnMore" />
        </Link>
    </>
);

NotificationBody.propTypes = {
    context: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    onSelectNotification: PropTypes.func.isRequired,
};

const NotificationToast = ({
    notification,
    onSelectNotification,
}) => {
    const { subject, context, code, createdAt, messageKey, notifications } = notification;

    const expanded = useSelectedToastId() === notification.id;
    const setExpanded = () => selectedToastId(expanded ? null : notification.id);
    useEffect(() => {
        UAT.dispatchAction('NOTIFICATION_TOAST_SHOW', {
            createdAt,
            messageKey,
            type: code,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <NotificationBody subject={subject} context={context} onSelectNotification={onSelectNotification} />
            {
                notifications?.length &&
                <div className="notification-center-toast__group">
                    <Link data-testId="toast-expander" onClick={setExpanded}>
                        <Icon className="notification-center-toast__expand-icon" name={expanded ? 'minus' : 'plus'} />
                        {
                            expanded ?
                                <Translate content="showLessNotifications" /> :
                                <Translate content="showMoreNotifications" params={{ count: notifications.length }} />
                        }
                    </Link>
                    {
                        expanded &&
                        <div className="notification-center-toast__notifications">
                            {
                                notifications.map(nestedNotification => (
                                    <div className="notification-center-toast__notification" key={nestedNotification.id}>
                                        <div className="notification-center-toast__icon-container" >
                                            <Icon
                                                intent={categoryIntentMap[nestedNotification.category]}
                                                name={categoryIconMap[nestedNotification.category]}
                                            />
                                        </div>
                                        <div>
                                            <NotificationBody
                                                subject={nestedNotification.subject}
                                                context={nestedNotification.context}
                                                onSelectNotification={onSelectNotification}
                                                intent={nestedNotification.intent}
                                            />
                                        </div>
                                    </div>
                                ),
                                )
                            }
                        </div>
                    }
                </div>
            }
        </>
    );
};

NotificationToast.propTypes = {
    notification: NotificationPropType.isRequired,
    onSelectNotification: PropTypes.func.isRequired,
};

export default NotificationToast;
