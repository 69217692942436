// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { useLoaderData, useParams, useLocation, useRevalidator, useMatches, UIMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { redirect } from 'jsw';

import LICENSE from 'queries/License.graphql';
import { ComponentType } from 'react';

type Route = {
    path?: string | string[];
    element?: React.ReactNode;
    componentLoader?: () => Promise<{ default: ComponentType<{ params: unknown }> }>;
    loader?: (args: { request: Request; params: Record<string, string> }) => Promise<Record<string, unknown> | Response | null>;
    isExist?: (args: { request: Request; params: Record<string, string> }) => Promise<boolean>;
    shouldRevalidate?: (args: { defaultShouldRevalidate: boolean; currentUrl: URL; nextUrl: URL }) => boolean;
    children?: Route[];
    [key: string]: unknown;
};

const LoadableComponent = () => {
    const matches = useMatches() as UIMatch<unknown, { isAllowedWithoutLicense: boolean }>[];
    const location = useLocation();
    const revalidator = useRevalidator();
    const { Component, ...data } = useLoaderData() as { Component: ComponentType<{ params: unknown }> };
    const params = useParams();
    const { data: licenseData } = useQuery(LICENSE);

    if (licenseData?.mode?.isLicenseLocked) {
        const isAllowedWithoutLicense = matches.some(({ handle }) => handle?.isAllowedWithoutLicense);
        if (!isAllowedWithoutLicense) {
            redirect('/cp/license/');
            return null;
        }
    }

    if (location.state?.reload) {
        delete location.state.reload;
        revalidator.revalidate();
    }

    return <Component {...data} params={params} />;
};

const componentLoaderTransformer = (route: Route): Route => {
    if (!route.componentLoader) {
        return route;
    }

    const { element, componentLoader, loader, ...other } = route;

    if (element) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        throw new Error('Unable to use options "componentLoader" and "element" at the same time', { cause: { route } });
    }

    return {
        ...other,
        element: <LoadableComponent />,
        loader: async ({ request, params }) => {
            const [Component, data] = await Promise.all([
                componentLoader().then(module => module.default || module),
                loader ? loader({ request, params }) : Promise.resolve(),
            ]);

            if (data instanceof Response) {
                return data;
            }

            return { ...data, Component };
        },
    };
};

const isExistTransformer = (route: Route): Route => {
    if (!route.isExist) {
        return route;
    }

    const { isExist, loader, ...other } = route;

    return {
        ...other,
        loader: async ({ request, params }) => {
            if (!(await isExist({ request, params }))) {
                throw new Response('Not Found', { status: 404 });
            }
            if (loader) {
                return loader({ request, params });
            }
            return null;
        },
    };
};

const shouldRevalidateTransformer = (route: Route): Route => {
    if (!route.loader) {
        return route;
    }

    const { shouldRevalidate, ...other } = route;

    return {
        ...other,
        shouldRevalidate: ({ defaultShouldRevalidate, ...args }) => {
            // Skip revalidation due to open/modify/close modals
            defaultShouldRevalidate &&= [...args.currentUrl.searchParams.keys(), ...args.nextUrl.searchParams.keys()]
                .every(param => !param.match(/^(modals\[.+]|module)$/));

            if (shouldRevalidate) {
                defaultShouldRevalidate = shouldRevalidate({ ...args, defaultShouldRevalidate });
            }

            return defaultShouldRevalidate;
        },
    };
};

const childrenTransformer = (route: Route): Route => {
    if (!Array.isArray(route.children)) {
        return route;
    }

    const { children, ...other } = route;

    return {
        ...other,
        children: routesTransformer(children),
    };
};

const pathTransformer = (route: Route): Route | Route[] => {
    if (!Array.isArray(route.path)) {
        return route;
    }

    const { path, ...other } = route;

    return path.map(path => ({
        ...other,
        path,
    }));
};

const routeTransformer = (route: Route) => {
    const transformedRoute = [
        componentLoaderTransformer,
        isExistTransformer,
        shouldRevalidateTransformer,
        childrenTransformer,
    ].reduce((route, transformer) => transformer(route), route);

    return pathTransformer(transformedRoute);
};

const routesTransformer = (routes: Route[]): Route[] => routes.map(routeTransformer).flat();

export {
    componentLoaderTransformer,
    isExistTransformer,
    shouldRevalidateTransformer,
    childrenTransformer,
    pathTransformer,
    routeTransformer,
};
export default routesTransformer;
