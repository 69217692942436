// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { onReady, redirectPost, showInternalError, keyCode } from 'jsw';

/* eslint-disable camelcase */

export const syn = s => {
    if (!s || !s.options || !s.options.length) {
        return false;
    }

    if (s.options[s.options.length - 1].selected) {
        s.options[s.options.length - 1].selected = false;
    }

    return true;
};

// ---------------------------------- redirect

export const go_to = href => {
    try {
        window.location = href;
    } catch {
    }
};

/**
 * @deprecated
 */
export const lon = () => true;

/**
 * @deprecated
 */
export const loff = () => true;

export const lsubmit = f => {
    try {
        if (f.lock.value === 'true') {
            return false;
        }
        f.lock.value = 'true';
    } catch {
    }

    lon();

    const button = document.getElementById('buttonid-ok');
    if (button) {
        button.disabled = true;
        button.classList.add('btn-waiting');
    }

    try {
        f.submit();
    } catch {
        loff();
        f.lock.value = 'false';
        return false;
    }
    return true;
};

export const getParentNodeByName = (o, name) => {
    const parent = o.parentNode;
    if (!parent) {
        return false;
    }
    if (parent.nodeName !== name) {
        return getParentNodeByName(parent, name);
    }
    return parent;
};

export const errorfield = (o, status) => {
    const tr = getParentNodeByName(o, 'TR');
    if (!tr) {
        return false;
    }
    tr.className = status ? 'error' : '';
    return true;
};

// global actions and behaviors
onReady(() => {
    document.querySelectorAll('textarea.js-auto-resize').forEach(element => {
        const resizeHandler = () => {
            const lines = element.value.split('\n').length;
            if (lines < originalSize) {
                element.rows = originalSize;
            } else {
                element.rows = lines + 2;
            }
        };

        const originalSize = element.rows;
        resizeHandler();

        element.addEventListener('keyup', resizeHandler);
    });

    // disable autofill for forms with disabled autocomplete fields (Chrome issue)
    document.querySelectorAll('form input[autocomplete="off"]').forEach(element => {
        element.closest('form').setAttribute('autocomplete', 'off');
    });
});

document.addEventListener('click', e => {
    const element = e.target.closest?.('a');
    if (!element) {
        return;
    }

    // prevent navigation to links with href="#"
    if (element.getAttribute('href') === '#') {
        e.preventDefault();
        return;
    }

    // use POST method for links with href and data-method="post"
    if (element.href && element.dataset.method === 'post') {
        e.preventDefault();
        redirectPost(element.href);
    }
});

// cross-site request forgery protection for legacy forms in 3d-party extensions
if ('undefined' !== typeof Prototype) {
    const appendForgeryProtection = form => {
        if (!form || (form.method && form.method.toUpperCase() === 'GET') || form.forgery_protection_token) {
            return;
        }
        const forgeryToken = document.getElementById('forgery_protection_token');
        if (!forgeryToken) {
            return;
        }
        form.appendChild(new Element('input', {
            type: 'hidden',
            name: 'forgery_protection_token',
            value: forgeryToken.content,
        }));
    };

    document.addEventListener('submit', ({ target }) => {
        const form = target.closest('form');
        appendForgeryProtection(form);
    });

    const origSubmit = HTMLFormElement.prototype.submit;
    HTMLFormElement.prototype.submit = function () {
        appendForgeryProtection(this);
        return origSubmit.call(this);
    };

    const origOpen = XMLHttpRequest.prototype.open;
    XMLHttpRequest.prototype.open = function (method, url, ...args) {
        origOpen.call(this, method, url, ...args);

        const forgeryToken = document.getElementById('forgery_protection_token');
        if (forgeryToken && (!url.match(/^(?:[a-z]+:)?\/\//) || url.indexOf(window.location.host) !== -1)) {
            this.setRequestHeader('X-Forgery-Protection-Token', forgeryToken.content);
        }
    };

    const origInitialize = Ajax.Request.prototype.initialize;
    Ajax.Request.prototype.initialize = function (url, options) {
        return origInitialize.call(this, url, {
            onException(transport, exception) {
                throw exception;
            },
            on400() {
                // Monkey patching Prototype for proper handling of session expiration
                window.location.reload();
            },
            on500(transport) {
                showInternalError(transport.responseText);
            },
            on0() {
                // do nothing in case of internal/network error
                return false;
            },
            ...options,
        });
    };

    Ajax.Request.prototype.abort = function () {
        this.transport.abort();
    };
}

// prevent AJAX request canceling on Escape button press in Firefox
document.addEventListener('keydown', event => {
    if (keyCode.ESC === event.keyCode) {
        event.preventDefault();
        event.stopPropagation();
    }
});
