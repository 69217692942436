// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { Text, Media, Status, Tooltip, Button } from '@plesk/ui-library';
import PropTypes from 'prop-types';
import { Locale } from 'jsw';
import { NotificationPropType } from '../lib/PropTypes';
import { getStatusIntent, getSubjectIntent } from '../lib/notificationIntent';
import UnreadCounter from '../UnreadCounter';

const Translate = Locale.getTranslate('components.notification-center');


const SubjectColumn = ({ notification, onSelectNotification, onToggleExpansionNotification, onUpdateNotifications, expandedRows }) => {
    const { id, subject, context, isRead, notifications } = notification;


    const isNotificationsGroup = !!notifications;
    const unreadCount = isNotificationsGroup ? notifications.filter(({ isRead }) => !isRead).length : 0;
    const notificationsId = isNotificationsGroup ? notifications.map(({ id }) => id) : [id];

    const subjectIntent = isNotificationsGroup ? getSubjectIntent(notification) : 'primary';
    return (
        <Media
            imageSpacing="xl"
            image={
                <div className="single-notification__status-container">
                    {
                        isNotificationsGroup ?
                            <UnreadCounter intent={getSubjectIntent(notification)} unreadCount={unreadCount} /> :
                            <Tooltip title={<Translate content={isRead ? 'markAsUnread' : 'markAsRead'} />}>
                                <Status
                                    intent={getStatusIntent(notification)}
                                    compact
                                    onClick={() => onUpdateNotifications(notificationsId, {
                                        isRead: !isRead,
                                    })}
                                    className="list-context-actions"
                                    data-type={`notification-is-read-${isRead}`}
                                />
                            </Tooltip>
                    }
                </div>
            }
        >
            <div
                onClick={() => onSelectNotification({ notification })}
                className="list-context-actions"
            >
                <Text
                    intent={subjectIntent}
                >
                    {context}
                </Text>
                <br />
                <Text
                    intent={subjectIntent}
                    bold={!isRead}
                >
                    {subject}
                </Text>
                <br />
                {isNotificationsGroup &&
                    <Button
                        ghost
                        onClick={() => onToggleExpansionNotification(notification)}
                        icon={expandedRows.includes(id) ? 'minus' : 'plus'}
                        className="single-notification__expander-button"
                    >
                        <Translate content={expandedRows.includes(id) ? 'showLess' : 'showMore'} />
                    </Button>}
            </div>
        </Media >
    );
};

SubjectColumn.propTypes = {
    notification: NotificationPropType.isRequired,
    onSelectNotification: PropTypes.func.isRequired,
    onToggleExpansionNotification: PropTypes.func.isRequired,
    onUpdateNotifications: PropTypes.func.isRequired,
    expandedRows: PropTypes.array.isRequired,
};

export default SubjectColumn;
