// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Brand from './Brand';
import CookiePolicy from './CookiePolicy';
import LayoutBackground from './LayoutBackground';

const LoginLayout = ({
    children = undefined,
    actions = undefined,
    ...props
}) => {
    useLayoutEffect(() => {
        document.body.classList.add('sid-login');
        return () => {
            document.body.classList.remove('sid-login');
        };
    }, []);

    return (
        <div className="login-page">
            <CookiePolicy layoutType="simplified" />
            <LayoutBackground
                header={(
                    <div className="login-page-header">
                        <div className="login-page-header__brand">
                            <Brand />
                        </div>
                        {actions ? (
                            <div className="login-page-header__actions">
                                {actions}
                            </div>
                        ) : null}
                    </div>
                )}
                {...props}
            >
                <div id="main" className="login-page__content">
                    {children}
                </div>
            </LayoutBackground>
        </div>
    );
};

LoginLayout.propTypes = {
    children: PropTypes.any,
    actions: PropTypes.any,
    customBackground: PropTypes.shape({
        file: PropTypes.string,
        color: PropTypes.string.isRequired,
        fit: PropTypes.string.isRequired,
    }),
};

export const withLoginLayout = (Component, extraProps) => {
    const Wrapper = wrapperProps => {
        const {
            contentAddon,
            actions,
            background,
            ...props
        } = {
            ...wrapperProps,
            ...(typeof extraProps === 'function' ? extraProps(wrapperProps) : extraProps),
        };

        return (
            <LoginLayout
                contentAddon={contentAddon}
                actions={actions}
                customBackground={background}
            >
                <Component {...props} />
            </LoginLayout>
        );
    };
    Wrapper.displayName = `withLoginLayout(${Component.displayName || Component.name || 'Component'})`;
    Wrapper.WrappedComponent = Component;

    return Wrapper;
};

export default LoginLayout;
