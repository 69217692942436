// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Translate as BaseTranslate } from '@plesk/ui-library';

const Translate = ({ component = Fragment, ...props }) => (
    <BaseTranslate component={component} {...props} />
);

Translate.propTypes = {
    component: PropTypes.elementType,
};

export default Translate;
