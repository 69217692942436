// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

/* eslint-disable react/no-multi-comp */

import { Container } from './container';
import { Component } from './component';
import render from './render';

import './pathbar.less';

/**
 * Example usage:
 *
 *     @example
 *     new Pathbar({
 *         renderTo: document.body,
 *         cls: 'pathbar',
 *         items: [{
 *             componentType: Pathbar.Item,
 *             title: 'Home',
 *         }, {
 *             componentType: Pathbar.Item,
 *             title: 'Domains',
 *         }, {
 *             componentType: Pathbar.Item,
 *             title: 'plesk.com',
 *         }, {
 *             componentType: Pathbar.Item,
 *             title: 'PHP Settings',
 *         }],
 *     });
 */
export class Pathbar extends Container {
    _initConfiguration(config) {
        super._initConfiguration(config);

        this._contentAreaId = `${this._id}-content-area`;
    }

    _initComponentElement() {
        if (document.getElementById(this._id)) {
            this._applyTargetId = this._id;
            super._initComponentElement();

            return;
        }

        super._initComponentElement();

        this._updateComponentElement(
            `<ul id="${this._contentAreaId}"></ul>`,
        );
    }

    _renderItem(item) {
        const renderTargetId = `${this._id}-item-${item.getId()}`;
        render(document.getElementById(this._contentAreaId), `<li id="${renderTargetId}"></li>`);
        render(document.getElementById(renderTargetId), item);
        this._renderItemSuffix(item);
    }

    _renderItemSuffix(item) {
        const renderTargetId = `${this._id}-item-${item.getId()}`;
        render(document.getElementById(renderTargetId), '<b>&gt;</b>');
    }
}

Pathbar.Item = class Item extends Component {
    /**
     * @cfg {String} title
     */
    /**
     * @cfg {String} href
     */

    _initConfiguration(config) {
        super._initConfiguration(config);

        this._title = this._getConfigParam('title', '');
        this._href = this._getConfigParam('href', '');
    }

    _initComponentElement() {
        super._initComponentElement();

        this._componentElement = document.createElement('a');
        this._componentElement.href = this._href;
        this._componentElement.innerHTML = `<span>${this._title}</span>`;
    }

    getHref() {
        return this._href;
    }
};

Pathbar.SimpleItem = class SimpleItem extends Component {
    /**
     * @cfg {String} title
     */

    _initConfiguration(config) {
        super._initConfiguration(config);

        this._title = this._getConfigParam('title', '');
    }

    _initComponentElement() {
        super._initComponentElement();

        this._componentElement = document.createElement('span');
        this._componentElement.innerHTML = this._title;
    }
};
