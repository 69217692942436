// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { NOTIFICATION_CATEGORIES } from './PropTypes';

export const getStatusIntent = ({ category, isRead }) => {
    if (isRead) {
        return 'inactive';
    }

    switch (category) {
        case NOTIFICATION_CATEGORIES.ALERT:
            return 'danger';
        case NOTIFICATION_CATEGORIES.WARNING:
            return 'warning';
        case NOTIFICATION_CATEGORIES.INFORMATION:
        case NOTIFICATION_CATEGORIES.FEEDBACK:
        case NOTIFICATION_CATEGORIES.PROMOTION:
            return 'info';
    }

    return 'inactive';
};

export const getSubjectIntent = ({ category }) => {
    switch (category) {
        case NOTIFICATION_CATEGORIES.ALERT:
            return 'danger';
        case NOTIFICATION_CATEGORIES.WARNING:
            return 'warning';
        case NOTIFICATION_CATEGORIES.PROMOTION:
            return 'info';
        case NOTIFICATION_CATEGORIES.INFORMATION:
        case NOTIFICATION_CATEGORIES.FEEDBACK:
            return 'inactive';
    }
    return 'inactive';
};
