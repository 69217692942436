// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

/* global update_oC:true */

import Cookie from 'common/cookie';
import { List } from './list';
import { ConfirmationPopupManager } from './confirmation-popup-manager';
import { AjaxPopupForm } from './ajax-popup-form';
import { FormAjax } from './form-ajax';
import { MessageBox, AjaxMessageBox, MultiCheckboxMessageWindow, WebspaceFoldersMessageBox } from './message-box';
import { getComponent } from './component';
import UAT, { request, getUrl, prepareNodeData } from 'common/user-activity-tracking';
import { isAnalyticsAllowed } from 'common/gdpr-storage';

const { PopupForm } = ConfirmationPopupManager;

const patchesFactory = () => {
    const wrap = (handler, fn, after) => {
        fn = fn || function () {
            // default empty handler
        };
        if (typeof fn === 'string') {
            try {
                eval(`fn = ${fn}`); // eslint-disable-line no-eval
            } catch {
                fn = function () {
                    // default empty handler
                };
            }
        }
        return after === true
            ? function (...args) {
                const result = fn.apply(this, args);
                args.push(result);
                try {
                    handler.apply(this, args);
                } catch {
                }
                return result;
            }
            : function (...args) {
                try {
                    handler.apply(this, args);
                } catch {
                }
                return fn.apply(this, args);
            };
    };

    return {
        ajaxRequest() {
            if (!window.Ajax) {
                return;
            }
            const ajaxRequestsHistory = {};
            const ajaxRequestProto = Ajax.Request.prototype;

            ajaxRequestProto.request = wrap(function () {
                const { url, options: { ignoreUAT }, method, parameters } = this;

                if (ignoreUAT || method.toLowerCase() === 'get') {
                    return;
                }

                const targetUrl = getUrl(url);
                const requestFingerprint = `${targetUrl} ${JSON.stringify(parameters)}`;

                if (ajaxRequestsHistory[requestFingerprint]) {
                    return;
                }

                ajaxRequestsHistory[requestFingerprint] = true;

                request({ name: 'REQUEST', url: getUrl(), data: { url: targetUrl } });
            }, ajaxRequestProto.request, true);
        },

        uiPointerForm() {
            document.addEventListener('DOMContentLoaded', function () {
                const lastUrl = Cookie.get('uat-data-source');
                if (lastUrl) {
                    document.querySelectorAll('div[data-source]').forEach(msg => {
                        const dataSource = msg.dataset.source;
                        if (dataSource && dataSource.indexOf(lastUrl) !== -1) {
                            let result = null;
                            switch (true) {
                                case msg.classList.contains('msg-warning'):
                                    result = 'WARNING';
                                    break;
                                case msg.classList.contains('msg-error'):
                                    result = 'ERROR';
                                    break;
                            }
                            request({ name: 'POST', url: getUrl(dataSource) }, null, result);
                        }
                    });
                    Cookie.remove('uat-data-source', '/');
                }
                if (typeof update_oC === 'undefined') { // eslint-disable-line camelcase
                    return;
                }
                update_oC = wrap(function (form) { // eslint-disable-line camelcase
                    Cookie.set('uat-data-source', getUrl(form.action), null, '/');
                }, update_oC, true);
            }, true);
        },

        jswFormAjax() {
            const formAjaxProto = FormAjax.prototype;

            formAjaxProto._onSubmit = wrap(function () {
                this._componentElement._formSubmit = wrap(function () {
                    const url = getUrl(this._componentElement.action);
                    request({ name: 'POST', post: { self: ['id', 'name'] }, url }, this._componentElement);
                }.bind(this), this._componentElement._formSubmit);
            }, formAjaxProto._onSubmit);

            formAjaxProto._onFailure = wrap(function (transport) {
                const name = this._componentElement.noRedirect ? 'APPLY' : 'OK';
                const url = getUrl(transport.request.url);
                request({ name, url, post: { self: ['id', 'name'] } }, this._componentElement, 'ERROR');
            }, formAjaxProto._onFailure);

            formAjaxProto._onSuccess = wrap(function (transport) {
                const name = this._componentElement.noRedirect ? 'APPLY' : 'OK';
                const url = getUrl(transport.request.url);
                const result = transport.responseJSON.formMessages
                    ? 'VALIDATION_ERROR'
                    : transport.responseJSON.status === 'error'
                        ? 'ERROR'
                        : null;
                request({ name, url, post: { self: ['id', 'name'] } }, this._componentElement, result);
            }, formAjaxProto._onSuccess);
        },

        jswList() {
            const listProto = List.prototype;

            listProto._submit = wrap(function (url, params) {
                const self = params.context || this;
                params.onSuccess = wrap(function (transport) {
                    const name = self._lastOperation ? self._lastOperation.toUpperCase() : 'POST';
                    const data = { selected: Object.keys(transport.request.parameters).length };
                    const result = transport.responseJSON.status === 'error' ? 'ERROR' : null;
                    request({ url: getUrl(transport.request.url), name, data }, null, result);
                }, params.onSuccess);

                params.onFailure = wrap(function (transport) {
                    const name = self._lastOperation ? self._lastOperation.toUpperCase() : 'POST';
                    const data = { selected: Object.keys(transport.request.parameters).length };
                    request({ url: getUrl(transport.request.url), name, data }, null, 'ERROR');
                }, params.onFailure);
            }, listProto._submit);

            listProto._showItemsNotSelectedWarning = wrap(function () {
                const name = this._lastOperation ? this._lastOperation.toUpperCase() : 'POST';
                request({ name, url: getUrl() }, null, 'NOT_SELECTED');
            }, listProto._showItemsNotSelectedWarning);

            listProto._getOperations = wrap(function (result) {
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                const self = this;
                const replaceHandlers = operations => {
                    operations.forEach(function (operation) {
                        if (operation.handler) {
                            operation.handler = wrap(function () {
                                const o = operation;
                                const last = o.id || o.title || o.description || o.addCls || '';
                                self._lastOperation = last
                                    .replace('button', '')
                                    .replace(' ', '')
                                    .replace('sb-', '')
                                    .replace('-', '');
                            }, operation.handler);
                        }
                        if (operation.operations) {
                            replaceHandlers(operation.operations);
                        }
                    });
                };
                replaceHandlers(result);
            }, listProto._getOperations, true);
        },

        confirmationPopupManager() {
            const popupFormProto = PopupForm.prototype;

            popupFormProto._onSuccess = wrap(function () {
                const name = typeof this._id === 'string' ? this._id.toUpperCase() : 'POST';
                request({ name, url: this._handlerUrl });
            }, popupFormProto._onSuccess);

            popupFormProto._onException = wrap(function () {
                const name = typeof this._id === 'string' ? this._id.toUpperCase() : 'POST';
                request({ name, url: this._handlerUrl }, null, 'ERROR');
            }, popupFormProto._onException);

            popupFormProto._onCancelClick = wrap(function () {
                request({ name: 'CANCEL', url: getUrl(), data: { popup: this._text } });
            }, popupFormProto._onCancelClick);
        },

        ajaxPopupForm() {
            AjaxPopupForm.prototype._onSuccess = wrap(function () {
                (function () {
                    const cancelBtn = getComponent('btn-cancel');
                    cancelBtn && cancelBtn.addEventObserver('click', function () {
                        request({ name: 'CANCEL', url: this._url });
                    }.bind(this));
                }).bind(this)
                    .defer();
            }, AjaxPopupForm.prototype._onSuccess, true);
        },

        messageBox() {
            const msgBoxProto = MessageBox.prototype;

            msgBoxProto._onNoClick = wrap(function () {
                request({ name: 'CANCEL', url: getUrl(), data: { popup: this._text } });
            }, msgBoxProto._onNoClick);
        },

        ajaxMessageBox() {
            const ajaxMsgBoxProto = AjaxMessageBox.prototype;

            ajaxMsgBoxProto._onSuccess = wrap(function () {
                request({ url: this._requestUrl });
            }, ajaxMsgBoxProto._onSuccess);
        },

        multiCheckboxMessageWindow() {
            const msgWindowProto = MultiCheckboxMessageWindow.prototype;

            msgWindowProto._onSuccess = wrap(function () {
                request({ url: this._requestUrl });
            }, msgWindowProto._onSuccess);
        },

        webspaceFoldersMessageBox() {
            const msgBoxProto = WebspaceFoldersMessageBox.prototype;

            msgBoxProto._onSuccess = wrap(function () {
                request({ url: this._requestUrl });
            }, msgBoxProto._onSuccess);
        },

        drawersWatcher() {
            const getDrawer = node => {
                if (!node.classList || !node.classList.contains('pul-layer')) {
                    return null;
                }

                const drawer = node.querySelector('.pul-drawer');
                if (!drawer) {
                    return null;
                }

                return drawer;
            };
            const reportDrawerAction = name => node => {
                const drawer = getDrawer(node);
                if (!drawer) {
                    return;
                }

                request({ name, data: prepareNodeData(drawer) });
            };
            document.addEventListener('DOMContentLoaded', () => {
                const callback = mutations => {
                    mutations.forEach(({ addedNodes, removedNodes }) => {
                        addedNodes.forEach(reportDrawerAction('OPEN-DRAWER'));
                        removedNodes.forEach(reportDrawerAction('CLOSE-DRAWER'));
                    });
                };
                const observer = new MutationObserver(callback);
                observer.observe(document.body, {
                    childList: true,
                    subtree: false,
                    attributes: false,
                    characterData: false,
                });
            });
        },
    };
};

const pleskActions = [
    {
        name: 'LOGIN',
        expects: [{
            sessionChanged: {},
        }],
    },
    {
        name: 'LOGOUT',
        expects: [{
            unloadByClick: {
                selector: '#account-menu-content-area a[href="/logout.php"]',
            },
        }],
    },
    {
        name: 'HELP',
        expects: [{
            click: {
                elements: [
                    {
                        selector: 'a[data-type="link-read-manual"]',
                    },
                ],
            },
        }],
    },
    {
        name: 'CANCEL',
        expects: [{
            click: {
                elements: [
                    {
                        selector: ['button[name="cancel"]', 'button[name="bname_cancel"]'],
                    },
                ],
            },
        }],
    },
    {
        name: 'SITE_PREVIEW',
        expects: [{
            click: {
                elements: [
                    {
                        selector: 'a[href*="/plesk-site-preview/"]',
                    },
                ],
            },
        }],
    },
    {
        name: 'RADIO_SELECT',
        expects: [{
            click: {
                elements: [{
                    selector: 'input[type="radio"]',
                    attributes: ['name', 'value'],
                }],
            },
        }],
    },
];

const watchersFactory = () => ({
    sessionChanged() {
        document.addEventListener('DOMContentLoaded', function () {
            const config = UAT.getConfig();

            if (isAnalyticsAllowed()) {
                if (Cookie.get('uat-iid') !== config.instanceId) {
                    Cookie.remove('uat-iid', '/');
                    Cookie.set('uat-iid', config.instanceId, null, '/');
                }
                if (Cookie.get('uat-sid') !== config.sessionId) {
                    Cookie.remove('uat-sid', '/');
                    Cookie.set('uat-sid', config.sessionId, null, '/');
                }
            }

            if (config.sessionId && window.localStorage.getItem('uat-sid') !== config.sessionId) {
                window.localStorage.setItem('uat-sid', config.sessionId);
                const data = {
                    userAgent: navigator.userAgent,
                    versionInfo: config.versionInfo,
                    accountCreated: config.accountCreated,
                    width: window.innerWidth,
                    height: window.innerHeight,
                    ratio: window.devicePixelRatio,
                };
                request({ name: 'LOGIN', url: getUrl(), data });
            }
        }, true);
    },
    unloadByClick({ selector }, expect, action) {
        window.addEventListener('beforeunload', () => {
            const el = document.querySelector(selector);
            if (el === document.activeElement) {
                request(action, el);
            }
        }, true);
    },
});

UAT.setPatches(patches => ({
    ...patches,
    ...patchesFactory(),
}));

UAT.setActions(actions => [
    ...actions,
    ...pleskActions,
]);

UAT.setWatchers(watchers => ({
    ...watchers,
    ...watchersFactory(),
}));

const { init } = UAT;
UAT.init = initConfig => {
    init(initConfig);
};

export { UAT };
