// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import {
    lazy,
    createContext,
    useContext,
    useMemo,
    useCallback,
    useState,
    Suspense,
} from 'react';
import TopBarProgress from 'react-topbar-progress-indicator';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

const ModulesContext = createContext({});

const MODULE_SEARCH_KEY = 'module';

const loadScope = (url, scope) => {
    if (window[scope]) {
        return window[scope];
    }
    const element = document.createElement('script');
    const promise = new Promise((resolve, reject) => {
        element.src = url;
        element.type = 'text/javascript';
        element.async = true;
        element.onload = () => resolve(window[scope]);
        element.onerror = reject;
    });
    document.head.appendChild(element);
    promise.finally(() => document.head.removeChild(element));
    return promise;
};

const loadModule = async (url, scope, module) => {
    try {
        const container = await loadScope(url, scope);
        await __webpack_init_sharing__('default');
        // eslint-disable-next-line camelcase
        await container.init(__webpack_share_scopes__.default);
        const factory = await container.get(module);
        return factory();
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error loading module:', error);
        return { default: () => null };
    }
};

const useDynamicModule = () => useContext(ModulesContext);

const ModuleProvider = ({ children }) => {
    const [loaded, setLoaded] = useState({});
    const navigate = useNavigate();

    const openModule = useCallback((moduleString, props) => {
        const { scope, entry, url } = /module:(?<scope>.+):(?<entry>.+)@(?<url>.+)/.exec(moduleString)?.groups ?? {};

        if (!(scope && url && entry)) {
            return;
        }
        const key = `${scope}:${entry}`;
        if (!loaded[key]) {
            setLoaded(prev => ({
                ...prev,
                [key]: lazy(() => loadModule(url, scope, `./${entry}`)),
            }));
        }
        navigate(`?${new URLSearchParams({ [MODULE_SEARCH_KEY]: key, props: encode(props) })}`, {
            preventScrollReset: true,
        });
    }, [navigate, loaded]);

    const closeModule = useCallback(() => {
        const params = new URLSearchParams(location.search);
        params.delete(MODULE_SEARCH_KEY);
        params.delete('props');
        navigate(`?${params}`, { preventScrollReset: true });
    }, [navigate]);

    const modules = useMemo(() => Object.entries(loaded).map(([key, module]) => ({
        key,
        Component: module,
    }))
    , [loaded]);

    const values = useMemo(() => ({
        modules,
        openModule,
        closeModule,
    }), [closeModule, modules, openModule]);

    return (<ModulesContext.Provider value={values}>{children}</ModulesContext.Provider>);
};

ModuleProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

const encode = object => btoa(JSON.stringify(object));
const decode = string => JSON.parse(atob(string));

const DynamicModule = () => {
    const { modules, closeModule } = useDynamicModule();
    const location = useLocation();

    const visible = useMemo(() => {
        const params = new URLSearchParams(location.search);
        if (params.has(MODULE_SEARCH_KEY)) {
            const key = params.get(MODULE_SEARCH_KEY);
            try {
                return {
                    [key]: decode(params.get('props')),
                };
            } catch {
                return false;
            }
        }
        return false;
    }, [location]);

    return (
        <Suspense fallback={<TopBarProgress />}>
            {modules.map(({ Component, key }) => (
                <Component key={key} {...visible[key]} isOpen={!!visible[key]} onClose={closeModule} />
            ))}
        </Suspense>
    );
};

export { ModuleProvider, DynamicModule, useDynamicModule };
