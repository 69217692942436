// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import {
    Button,
    List,
    ListEmptyView,
    SearchBar,
    Toolbar,
    ToolbarExpander,
    ToolbarGroup,
} from '@plesk/ui-library';
import PropTypes from 'prop-types';
import { Locale } from 'jsw';
import applyFilter from '../lib/applyFilter';
import { NotificationFilterPropType, NotificationPropType } from '../lib/PropTypes';
import SubjectColumn from './SubjectColumn';

import groupNotifications from '../lib/groupNotifications';
import DateColumn from './DateColumn';
import NotificationActions from '../NotificationActions';
import FilterMenu from './FilterMenu';

import './NotificationCenterList.less';
import { useState } from 'react';

const Translate = Locale.getTranslate('components.notification-center');

const NotificationCenterList = ({
    notifications,
    filter = null,
    onUpdateFilter,
    onSelectNotification,
    onUpdateNotifications,
    onRemoveNotification,
    onMarkAllAsRead,
}) => {
    const notificationsToDisplay = applyFilter(notifications, filter);
    const [expandedRows, setExpandedRows] = useState([]);
    const filterCount = Object.values(filter).reduce((r, v) => r + (v ? 1 : 0), 0);
    const grouppedNotifications = groupNotifications(notificationsToDisplay);

    const selectNotification = ({ notification }) => {
        if (notification.notifications) {
            return;
        }
        onSelectNotification({ notification });
    };

    const toggleExpansionNotification = notification => {
        setExpandedRows(prevExpandedRows => {
            if (prevExpandedRows.includes(notification.id)) {
                return prevExpandedRows.filter(id => id !== notification.id);
            }
            return [...prevExpandedRows, notification.id];
        });
        if (notification.notifications) {
            const unreadNotificationIds = notification.notifications.filter(({ isRead }) => !isRead).map(({ id }) => id);
            if (unreadNotificationIds.length) {
                onUpdateNotifications(unreadNotificationIds, { isRead: true });
            }
        }
    };

    const columns = [
        {
            key: 'subject',
            type: 'title',
            // eslint-disable-next-line react/display-name,react/prop-types
            render: notification => (
                <SubjectColumn
                    notification={notification}
                    onSelectNotification={selectNotification}
                    onUpdateNotifications={onUpdateNotifications}
                    onToggleExpansionNotification={toggleExpansionNotification}
                    expandedRows={expandedRows}
                />
            ),
        },
        {
            key: 'date',
            // eslint-disable-next-line react/display-name,react/prop-types
            render: notification => (
                <DateColumn
                    notification={notification}
                    onSelectNotification={onSelectNotification}
                />
            ),
            width: '10%',
        },
        {
            key: 'actions',
            type: 'actions',
            width: '80px',
            // eslint-disable-next-line react/display-name,react/prop-types
            render: notification => (
                <NotificationActions
                    notification={notification}
                    onUpdateNotifications={onUpdateNotifications}
                    onRemoveNotification={onRemoveNotification}
                />
            ),
        },
    ];

    const toolbar = (
        <Toolbar>
            <ToolbarGroup title="">
                <Button data-type="mark-all-as-read" icon="mail" onClick={onMarkAllAsRead}>
                    <Translate content="markAllAsRead" />
                </Button>
            </ToolbarGroup>
            <ToolbarExpander />
            <ToolbarGroup title="">
                <FilterMenu
                    filter={filter}
                    filterCount={filterCount}
                    onUpdateFilter={onUpdateFilter}
                />
            </ToolbarGroup>
            <ToolbarGroup title="">
                <SearchBar
                    inputProps={{ placeholder: Locale.getSection('components.notification-center').lmsg('searchPlaceholder'), value: filter.search }}
                    onTyping={value => onUpdateFilter({ search: value })}
                    minWidth={200}
                />
            </ToolbarGroup>
        </Toolbar>
    );

    return (
        <List
            rowKey="id"
            className="notification-center-list"
            columns={columns}
            data={grouppedNotifications}
            renderRowBody={row => row.notifications?.map(notification => (
                <div key={notification.id} className="single-notification">
                    <div className="single-notification__subject">
                        <SubjectColumn
                            onSelectNotification={selectNotification}
                            onUpdateNotifications={onUpdateNotifications}
                            notification={notification}
                            expandedRows={expandedRows}
                            onToggleExpansionNotification={toggleExpansionNotification}
                        />
                    </div>
                    <DateColumn
                        notification={notification}
                        onSelectNotification={onSelectNotification}
                    />
                    <div className="single-notification__actions">
                        <NotificationActions
                            notification={notification}
                            onUpdateNotifications={onUpdateNotifications}
                            onRemoveNotification={onRemoveNotification}
                        />
                    </div>
                </div>
            ))}
            expandedRows={expandedRows}
            onExpandedRowsChange={setExpandedRows}
            toolbar={toolbar}
            emptyViewMode={filterCount ? 'items' : 'all'}
            emptyView={filterCount ? (
                <ListEmptyView
                    reason="filtered"
                    title={<Translate content="listFilteredTitle" />}
                    description={<Translate content="listFilteredDescription" />}
                />
            ) : (
                <ListEmptyView
                    title={<Translate content="listEmptyTitle" />}
                    image={require('images/notification-center-empty.svg')}
                    description={<Translate content="listEmptyDescription" />}
                />
            )}
        />
    );
};

NotificationCenterList.propTypes = {
    notifications: PropTypes.arrayOf(NotificationPropType.isRequired).isRequired,
    filter: NotificationFilterPropType,
    onUpdateNotifications: PropTypes.func.isRequired,
    onSelectNotification: PropTypes.func.isRequired,
    onUpdateFilter: PropTypes.func.isRequired,
    onRemoveNotification: PropTypes.func.isRequired,
    onMarkAllAsRead: PropTypes.func.isRequired,
};

export default NotificationCenterList;
