// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { Component } from './component';
import { Tooltip } from './tooltip';
import render from './render';
import createElement from './createElement';
import escapeHtml from './escapeHtml';
import emptyFn from './emptyFn';
import { createHtmlElement } from 'helpers/createHtmlElement';

export class SplitButton extends Component {
    _initConfiguration(config) {
        super._initConfiguration({
            cls: 'btn-group',
            ...config,
        });

        this.title = this._getConfigParam('title', '');
        this.isAction = this._getConfigParam('isAction', false);
        this.isDefault = this._getConfigParam('isDefault', false);
        this.isMenuFlip = this._getConfigParam('isMenuFlip', false);
        this.onclick = this._getConfigParam('onclick', emptyFn);
        this.items = this._getConfigParam('items', []);
        this.isScrollable = this._getConfigParam('isScrollable');
    }

    _initComponentElement() {
        super._initComponentElement();

        render(this._componentElement, [
            createElement(`button.btn${this.isAction ? '.btn-primary' : ''}`,
                {
                    type: this.isDefault ? 'submit' : 'button',
                    onclick: this.onclick,
                },
                escapeHtml(this.title),
            ),
            createElement('button.btn.dropdown-toggle',
                {
                    type: 'button',
                    onclick: this.openDropdown.bind(this),
                },
                createElement('em.caret'),
            ),
            createElement(`ul.dropdown-menu${this.isMenuFlip ? '.dropdown-menu-flip' : ''}${this.isScrollable ? '.dropdown-scrollable' : ''}`),
        ]);

        if (Array.isArray(this.items)) {
            this._initItems();
        }
    }

    _initItems() {
        this.items.forEach(item => {
            const link = createHtmlElement('a', {
                id: item.id || null,
                href: item.href || null,
                target: item.newWindow ? '_blank' : null,
            });
            link.innerHTML = escapeHtml(item.title);

            const listItem = createHtmlElement('li', {
                class: (item.submenu ? 'dropdown-submenu' : '') + (this.isMenuFlip ? ' to-left' : ''),
            });
            render(listItem, link);

            if (item.submenu) {
                render(listItem, '<ul class="dropdown-menu"></ul>');
            }

            render(this._componentElement.querySelector('.dropdown-menu'), listItem);

            link.addEventListener('click', item.onclick || emptyFn);

            if (item.tooltip) {
                Tooltip.init(link, { text: item.tooltip });
            }
        });
    }

    _addTooltips() {
        const description = this._getConfigParam('description');
        if (description) {
            this._tooltip = Tooltip.init(this._componentElement.querySelector('button'), { text: description });
        }
    }

    openDropdown(event) {
        event.preventDefault();
        event.stopPropagation();

        if (typeof this.items === 'function') {
            const dropdownMenu = this._componentElement.querySelector('.dropdown-menu');
            dropdownMenu.innerHTML = `<div class="ajax-loading">${this.lmsg('loading')}</div>`;

            const { items } = this;
            this.items = [];

            items().then(items => {
                dropdownMenu.innerHTML = '';
                this.items = items;
                this._initItems();
            });
        }

        if (!this._componentElement.classList.contains('open')) {
            document.querySelectorAll('.btn-group').forEach(item => {
                item.classList.remove('open');
            });
        }
        this._componentElement.classList.toggle('open');
    }

    _addEvents() {
        const openSubmenu = menuItem => {
            const submenu = menuItem.querySelector('.dropdown-menu');
            if (!menuItem.classList.contains('open') && 'undefined' !== typeof submenu && !submenu.innerHTML.trim()) {
                this.items[this.items.length - 1].submenu ? this.items[this.items.length - 1].submenu(menuItem.querySelector('a').nextElementSibling) : '';
            }
            closeSubmenu(menuItem);
            menuItem.classList.add('open');
            clearTimeout(menuItem._menuTimeout);
        };

        const closeSubmenu = menuItem => {
            menuItem.classList.remove('open');
            clearTimeout(menuItem._menuTimeout);
        };

        this._componentElement.querySelectorAll('.dropdown-menu > li').forEach(element => {
            element.addEventListener('mouseover', () => {
                if (element.classList.contains('dropdown-submenu') && element.classList.contains('open')) {
                    return;
                }
                element.parentNode.querySelectorAll('.dropdown-submenu.open').forEach(menuItem => {
                    closeSubmenu(menuItem);
                });
            });
        });

        const submenu = this._componentElement.querySelectorAll('.dropdown-submenu');
        submenu.forEach(item => {
            item.addEventListener('mouseover', () => {
                openSubmenu(item);
            });
            const a = item.querySelector('a');
            a.addEventListener('touchend', () => {
                if (item.classList.contains('open')) {
                    closeSubmenu(item);
                } else {
                    openSubmenu(item);
                }
            });
            a.addEventListener('click', event => {
                event.stopPropagation();
                event.preventDefault();
            });
        });

        this._componentElement.querySelectorAll('.dropdown-submenu').forEach(menuItem => {
            menuItem.addEventListener('mouseleave', () => {
                clearTimeout(menuItem._menuTimeout);
                menuItem._menuTimeout = setTimeout(() => {
                    closeSubmenu(menuItem);
                }, 500);
            });
        });

        document.body.addEventListener('click', () => {
            this._componentElement.classList.remove('open');
        });
    }

    disable() {
        const button = this._componentElement.querySelector('button');
        button.disabled = true;
        button.classList.add('disabled');

        const toggle = this._componentElement.querySelector('.dropdown-toggle');
        toggle.disabled = true;
        toggle.classList.add('disabled');

        if (Tooltip.current === this._tooltip) {
            Tooltip.hide();
        }
    }

    enable() {
        const button = this._componentElement.querySelector('button');
        button.disabled = false;
        button.classList.remove('disabled');

        const toggle = this._componentElement.querySelector('.dropdown-toggle');
        toggle.disabled = false;
        toggle.classList.remove('disabled');
    }

    setText(text) {
        this._componentElement.querySelector('button').innerHTML = text;
    }
}
