// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import apolloClient from 'apolloClient';
import { Locale } from 'jsw';

export const domainDatabaseRoute = ({ loader }) => ({
    path: 'databases',
    handle: {
        crumb: () => ({ title: Locale.getSection('components.routes').lmsg('databases') }),
        pageTitle: ({ matchesContext }) => {
            if (!matchesContext.domainRouteData) {
                return null;
            }
            const { domain } = matchesContext.domainRouteData;

            return Locale.getSection('components.routes').lmsg('databaseList', { domainName: `<b>${domain.name}</b>` });
        },
        key: ({ params }) => params.domainId,
    },
    componentLoader: () => import(/* webpackChunkName: "components/pages/DatabasesPage" */'components/pages/DatabasesPage'),
    loader: loader('/smb/database/list/domainId/:domainId'),
    children: [
        {
            path: ':databaseId/connection-info',
            componentLoader: () => import(/* webpackChunkName: "components/pages/DatabasesPage/ConnectionInfo" */'components/pages/DatabasesPage/ConnectionInfo'),
            loader: async ({ params }) => {
                const { data } = await apolloClient.query({
                    query: require('components/pages/DatabasesPage/ConnectionInfo.graphql'),
                    variables: { id: btoa(`Database:${params.databaseId}`) },
                });

                return data;
            },
        },
        {
            path: 'add',
            componentLoader: () => import(/* webpackChunkName: "components/pages/DatabasesPage/AddDatabase" */'components/pages/DatabasesPage/AddDatabase'),
            loader: loader('/smb/database/create-data/domainId/:domainId'),
        },
    ],
});
