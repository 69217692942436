// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { useRouteError, isRouteErrorResponse } from 'react-router-dom';
import { LocaleProvider } from '@plesk/ui-library';
import { Locale } from 'jsw';
import ErrorPage from 'common/components/ErrorPage';
import { isNetworkError } from 'helpers/isNetworkError';

const AppBoundary = () => {
    const error = useRouteError();

    // eslint-disable-next-line no-console
    console.error(error);

    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            return (
                <LocaleProvider messages={Locale.getSection('controllers.error.error').messages}>
                    <ErrorPage code="404" message="Page Not Found" />
                </LocaleProvider>
            );
        }
    }

    if (error.graphQLErrors?.length) {
        const [graphQLError] = error.graphQLErrors;
        return (
            <LocaleProvider messages={Locale.getSection('controllers.error.error').messages}>
                <ErrorPage
                    code={graphQLError.extensions?.category === 'notFound' && '404'}
                    exception={{
                        type: graphQLError.extensions?.category === 'notFound' ? 'Not Found' : 'GraphQL Error',
                        message: graphQLError.extensions?.debugMessage ?? graphQLError.message,
                        file: graphQLError.trace?.[0].file,
                        line: graphQLError.trace?.[0].line,
                        stackTrace: graphQLError.trace?.map(({ call, file, line }, index) => `#${index} ${file}(${line}): ${call}`).join('\n'),
                    }}
                />
            </LocaleProvider>
        );
    }

    return (
        <LocaleProvider messages={Locale.getSection('controllers.error.error').messages}>
            <ErrorPage
                isNetworkError={isNetworkError(error)}
                exception={{
                    type: error.name,
                    message: error.message,
                    stackTrace: error.stack,
                }}
            />
        </LocaleProvider>
    );
};

export default AppBoundary;
