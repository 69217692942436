// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { Label } from '@plesk/ui-library';
import PropTypes from 'prop-types';

import './UnreadCounter.less';

const UnreadCounter = ({ intent, unreadCount }) => <Label className="notification-center-notification__unread-counter" intent={intent}>{unreadCount}</Label>;


UnreadCounter.propTypes = {
    intent: PropTypes.string.isRequired,
    unreadCount: PropTypes.number.isRequired,
};

export default UnreadCounter;
