// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { Locale } from 'jsw';
import apolloClient from 'apolloClient';

import BANDWIDTH_LIMITING_PAGE from 'queries/BandwidthLimitingPage.graphql';

export const bandwidthLimitingDomainRoute = {
    path: 'bandwidth-limiting',
    componentLoader: () => import(/* webpackChunkName: "components/pages/BandwidthLimitingPage" */'components/pages/BandwidthLimitingPage'),
    loader: async ({ params }) => {
        const { data } = await apolloClient.query({
            query: BANDWIDTH_LIMITING_PAGE,
            variables: { id: btoa(`Domain:${params.domainId}`) },
        });
        return data;
    },
    handle: {
        helpContext: 'client.domain.hosting.performance',
        crumb: ({ matchesContext }) => {
            const { domain } = matchesContext.domainRouteData;

            const locale = Locale.getSection('components.routes');
            return { title: locale.lmsg('bandwidthLimiting', { domainName: `<b>${domain.name}</b>` }) };
        },
        key: ({ params }) => params.domainId,
    },
};
