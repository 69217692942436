// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

export const createHtmlElement = (tagName: string, attrs: Record<string, string | boolean> = {}): HTMLElement => {
    const el = document.createElement(tagName);

    Object.entries(attrs).forEach(([name, value]) => {
        if (value !== null && value !== false) {
            el.setAttribute(name, `${value}`);
        }
    });

    return el;
};
