// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { Container } from './container';
import { Tooltip } from './tooltip';
import Filters from './list/Filters';
import createComponent from './createComponent';
import render from './render';
import createElement from './createElement';
import emptyFn from './emptyFn';

import './small-tools.less';

/**
 * Example usage:
 *
 *     @example
 *     new SmallTools({
 *         renderTo: document.body,
 *         operations: [{
 *             componentType: SmallButton,
 *             title: 'Button 1',
 *             handler() {
 *                 alert('You clicked the Button 1!');
 *             },
 *         }, {
 *             componentType: bar.Separator,
 *         }, {
 *             componentType: SmallButton,
 *             title: 'Button 2',
 *             handler() {
 *                 alert('You clicked the Button 2!');
 *             },
 *         }],
 *     });
 */
export class SmallTools extends Container {
    /**
     * @cfg {Object[]} operations
     */

    _initConfiguration(config) {
        super._initConfiguration(config);

        this._operations = this._getConfigParam('operations', null);
        this._operationsId = this._getConfigParam('operationsId', `${this._id}-operations`);
        this._cls = this._getConfigParam('cls', 'actions-box');

        if (Array.isArray(this._operations) && !this._operations.length) {
            this._operations = null;
        }

        this.searchFilters = $H(this._getConfigParam('searchFilters', {}));
        this.searchFiltersState = this._getConfigParam('searchFiltersState', {});
        this.searchHandler = this._getConfigParam('searchHandler', emptyFn);
        this.resetSearchHandler = this._getConfigParam('resetSearchHandler', emptyFn);
        this.listId = this._getConfigParam('listId');

        this.searchOveral = this._getConfigParam('searchOveral', null);
        if (null === this.searchOveral) {
            this.searchFilters.each(({ key, value }) => {
                if (value.overal) {
                    this.searchOverall = key;
                }
            });
            if (null === this.searchOveral && 1 === this.searchFilters.keys().length) {
                this.searchOveral = this.searchFilters.keys()[0];
            }
            if (null === this.searchOveral) {
                this.searchOveral = false;
            }
        }

        this.quickSearchWide = this._getConfigParam('quickSearchWide', false);
        this.quickSearchInputId = this._getConfigParam('quickSearchInputId');
        this.searchCollapsed = this._getConfigParam('searchCollapsed', false);
        this.onToggleSearch = this._getConfigParam('onToggleSearch', emptyFn);
    }

    render() {
        super.render();

        render(this._componentElement, [
            createElement(`#${this._operationsId}.objects-toolbar.clearfix`,
                this._operations ? this._operations.map(createComponent) : '',
                this.quickSearchBoxView(),
            ),
            this.searchBoxView(),
        ]);

        this._componentElement.classList[this.searchCollapsed ? 'remove' : 'add']('actions-box-search-show');

        if (this._operations) {
            this.addResponsiveButton('toolbar');
        }

        if (this.searchFilters.keys().length) {
            this.addResponsiveButton('search');
        }

        Tooltip.initData(this._componentElement);
    }

    quickSearchBoxView() {
        if (!this.searchFilters.keys().length) {
            return '';
        }

        return createElement(`.quick-search-box${this.quickSearchWide ? '.quick-search-box-wide' : ''}`,
            {
                onclick(event) {
                    event.stopPropagation();
                },
            },
            this.searchOveralView(),
            this.searchFilters.keys().length > 1 ? createElement('span.search-control',
                createElement('a.s-btn.sb-search-show', {
                    onclick: this.toggleSearch.bind(this),
                    'aria-label': this.lmsg('showSearch'),
                }),
                createElement('a.s-btn.sb-search-hide', {
                    onclick: this.toggleSearch.bind(this),
                    'aria-label': this.lmsg('hideSearch'),
                }),
            ) : '',
        );
    }

    searchOveralView() {
        if (!this.searchOveral) {
            return '';
        }

        const filterState = this.searchFiltersState[this.searchOveral];
        const currentValue = this.searchFilters.get(this.searchOveral).value || (filterState && filterState.searchText) || '';

        return createElement('.search-field',
            createElement('form',
                {
                    onsubmit: event => {
                        event.preventDefault();
                        this.searchHandler(event.target);
                    },
                },
                createElement(`input${this.quickSearchInputId ? `#${this.quickSearchInputId}` : ''}`, {
                    type: 'text',
                    value: currentValue,
                    name: `searchFilter[${this.searchOveral}][searchText]`,
                }),
                createElement('em',
                    {
                        onclick: event => {
                            this.searchHandler(event.target.closest('form'));
                        },
                    },
                    createElement('span'),
                ),
            ),
        );
    }

    searchBoxView() {
        if (this.searchFilters.keys().length < 2) {
            return null;
        }

        return createElement('.search-box',
            {
                onclick(event) {
                    event.stopPropagation();
                },
            },
            createElement('form',
                {
                    onsubmit: event => {
                        event.preventDefault();
                        this.searchHandler(event.target);
                    },
                },
                createElement('ul',
                    this.searchFiltersView(),
                ),
                createElement('a.s-btn.sb-search',
                    {
                        onclick: event => {
                            this.searchHandler(event.target.closest('form'));
                        },
                    },
                    this.lmsg('buttonSearch'),
                ),
                createElement('a.s-btn.sb-show-all',
                    {
                        onclick: () => {
                            this.resetSearchHandler();
                        },
                    },
                    this.lmsg('buttonResetSearch'),
                ),
                createElement('input', {
                    type: 'image',
                    style: 'border: 0pt none; height: 0pt; width: 0pt; position: absolute; left: -9999px;',
                    src: require('images/blank.gif'),
                }),
            ),
        );
    }

    searchFiltersView() {
        const filters = [];

        this.searchFilters.each(({ key: name, value: config }) => {
            filters.push(
                createElement('li',
                    createElement('div',
                        config.title ? createElement('span', config.title) : '',
                        Filters.create({
                            idPrefix: this.listId,
                            name,
                            value: this.searchFiltersState[name] && this.searchFiltersState[name].searchText,
                            ...config,
                        }, event => {
                            this.searchHandler(event.target.closest('form'));
                        }),
                    ),
                ),
            );
        });

        return filters;
    }

    toggleSearch() {
        this._componentElement.classList.toggle('actions-box-search-show');
        this.onToggleSearch(!this._componentElement.classList.contains('actions-box-search-show'));
    }

    _addEvents() {
        super._addEvents();

        document.addEventListener('click', () => {
            this._hideResponsiveButtonBlocks();
        });
    }

    addResponsiveButton(name) {
        if (!this._rActionsElement) {
            this._rActionsElement = new Element('div', { class: 'r-actions' });
            this._rActionsElement.innerHTML = '<ul class="r-actions-list"></ul>';
            render(this._componentElement, this._rActionsElement, 'top');
        }

        if (this._rActionsElement.querySelector(`.r-actions-${name}`)) {
            return;
        }

        render(this._rActionsElement.querySelector('.r-actions-list'), (
            createElement(`li.r-actions-item.r-actions-${name}`, { onclick: e => this.onResponsiveButtonClick(e, name) }, createElement('span'))
        ));
    }

    onResponsiveButtonClick(event, name) {
        const button = this._componentElement.querySelector(`.r-actions-${name}`);
        const show = !button.classList.contains('r-actions-item-active');
        this._hideResponsiveButtonBlocks();
        if (show) {
            this._showResponsiveButtonBlocks(name);
        }
        event.stopPropagation();
    }

    _hideResponsiveButtonBlocks() {
        this._componentElement.className.split(' ').forEach(className => {
            if (className.indexOf('r-actions-active') === 0) {
                this._componentElement.classList.remove(className);
            }
        });
        this._componentElement.querySelectorAll('.r-actions-item-active').forEach(item => {
            if (item.classList.contains('r-actions-select')) {
                return;
            }
            item.classList.remove('r-actions-item-active');
        });
    }

    _showResponsiveButtonBlocks(name) {
        this._componentElement.querySelector(`.r-actions-${name}`).classList.add('r-actions-item-active');
        this._componentElement.classList.add('r-actions-active');
        this._componentElement.classList.add(`r-actions-active-${name}`);
    }
}
