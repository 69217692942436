// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { useState } from 'react';

const useListOperation = () => {
    const [selection, setSelection] = useState([]);
    const [loadingRows, setLoadingRows] = useState([]);

    const wrapListOperation = operation => async function (keys, ...args) {
        setSelection(currentKeys => (
            currentKeys.filter(key => !keys.includes(key))
        ));
        setLoadingRows(currentKeys => [...currentKeys, ...keys]);

        try {
            return await operation.call(this, keys, ...args);
        } finally {
            setLoadingRows(currentKeys => (
                currentKeys.filter(key => !keys.includes(key))
            ));
        }
    };

    return {
        selection,
        setSelection,
        loadingRows,
        wrapListOperation,
    };
};

export default useListOperation;
