// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

interface ReadLocalStorageValue {
    <V>(key: string, defaultValue: V): V;
    <V = undefined>(key: string): V | undefined;
}

export const readLocalStorageValue: ReadLocalStorageValue = <Value>(
    key: string,
    defaultValue?: Value,
): Value | undefined => {
    try {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : defaultValue;
    } catch {
        return defaultValue;
    }
};
