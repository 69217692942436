// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { Locale } from 'jsw';
import apolloClient from 'apolloClient';
import { readGraphQlListInputFromStorage } from 'components/common/GraphQlList';
import TRAFFIC_USAGE_INTERVALS from 'queries/TrafficUsageIntervals.graphql';
import TRAFFIC_USAGE_BY_RESELLER_CLIENTS_LIST from 'queries/TrafficUsageByResellerClientsList.graphql';
import TOTAL_TRAFFIC_USAGE_BY_RESELLER from 'queries/TotalTrafficUsageByReseller.graphql';
import { gql } from '@apollo/client';
import { trafficUsageByCustomerDomainsRoute } from 'routes/trafficUsageByCustomerDomainsRoute';
import { trafficUsageByClientDomainsRoute } from 'routes/trafficUsageByClientDomainsRoute';
import { trafficUsageByDomainRoute } from 'routes/trafficUsageByDomainRoute';

export const trafficUsageByResellerRoute = (
    path,
    { clientIdParamName },
) => ({
    path,
    handle: {
        crumb: ({ data }) => ({ title: data.client.name }),
        pageTitle: ({ data }) => Locale.getSection('components.routes').lmsg(
            'trafficUsageByReseller',
            { resellerName: data.client.name },
        ),
    },
    loader: async ({ params }) => {
        const clientId = params[clientIdParamName];
        const clientNodeId = btoa(`Client:${clientId}`);

        const { data: { client } } = await apolloClient.query({
            query: gql`
                query($clientId: ID!) {
                    client: node(id: $clientId) {
                        ... on Client {
                            id
                            name
                        }
                    }
                }
            `,
            variables: { clientId: clientNodeId },
        });

        return { client };
    },
    children: [
        {
            index: true,
            componentLoader: () => import(
                /* webpackChunkName: "components/pages/TrafficUsageByResellerPage" */
                'components/pages/TrafficUsageByResellerPage'
            ),
            loader: async ({ params }) => {
                const clientId = params[clientIdParamName];
                const clientNodeId = btoa(`Client:${clientId}`);
                const trafficUsageListKey = `TrafficUsageByResellerCustomersList${clientId}`;

                const [{ data: intervalsData }] = await Promise.all([
                    apolloClient.query({ query: TRAFFIC_USAGE_INTERVALS }),
                    apolloClient.query({
                        query: TOTAL_TRAFFIC_USAGE_BY_RESELLER,
                        variables: { clientNodeId },
                    }),
                    apolloClient.query({
                        query: TRAFFIC_USAGE_BY_RESELLER_CLIENTS_LIST,
                        variables: {
                            clientNodeId,
                            input: {
                                ...readGraphQlListInputFromStorage({ key: trafficUsageListKey }),
                                clientTypes: ['client'],
                            },
                        },
                    }),
                ]);

                const { trafficUsageIntervals } = intervalsData.viewer.client.statistics;

                return {
                    trafficUsageIntervals,
                    clientNodeId,
                    trafficUsageListKey,
                };
            },
        },
        trafficUsageByCustomerDomainsRoute('customers/:customerId/domains'),
        {
            path: 'domains',
            handle: {
                crumb: () => ({
                    title: Locale.getSection('components.routes').lmsg('trafficUsageByDomains'),
                }),
                helpContext: 'client.domain.traffic',
            },
            children: [
                trafficUsageByClientDomainsRoute({
                    clientIdParamName,
                    clientType: 'reseller',
                }),
                trafficUsageByDomainRoute({ path: ':domainId' }),
            ],
        },
    ],
});
