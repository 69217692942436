// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import classNames from 'classnames';
import { Layout } from '@plesk/ui-library';
import PropTypes from 'prop-types';

const LayoutBackground = ({
    customBackground = {
        file: undefined,
        color: '#4B4B4B',
        fit: 'fill',
    },
    header = undefined,
    className = '',
    ...props
}) => (
    <Layout
        type="simplified"
        className={classNames(
            'login-page__inner',
            `login-page__background login-page__background--${customBackground.fit}`,
            className,
        )}
        style={{
            '--login-bg-color': customBackground.color,
            '--login-bg-image': customBackground.file ? `url(/images/${customBackground.file})` : null,
        }}
        header={header}
        {...props}
    />
);

LayoutBackground.propTypes = {
    customBackground: PropTypes.shape({
        file: PropTypes.string,
        color: PropTypes.string.isRequired,
        fit: PropTypes.string.isRequired,
    }),
    className: PropTypes.string,
    header: PropTypes.node,
};

export default LayoutBackground;
