// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { useQuery, FetchPolicy, TypedDocumentNode, QueryResult } from '@apollo/client';
import { PaginationProps } from '@plesk/ui-library';
import { useLocalStorage } from 'common/hooks';
import { ListPageInfo, ListSortInfo } from 'schema';
import {
    GraphQlListContextType,
    SetSearchFilters,
    SetFilters,
    SetSort,
    SetPage,
    SetItemsPerPage,
} from './GraphQlListContext';
import { getGraphQlListInputStorageArgs } from './helpers';

const ITEMS_PER_PAGE_OPTIONS: PaginationProps['itemsPerPageOptions'] = [10, 25, 100, 'all'];

type BaseVariables = {
    input?: {
        pagination?: { page?: number; itemsPerPage?: number | null };
        searchFilters?: Record<string, string>;
        orderBy?: Record<string, 'ASC' | 'DESC'>;
    },
    [key: string]: unknown;
};

type BaseListData = {
  nodes: Array<unknown>;
  pageInfo: ListPageInfo;
  sortInfo: ListSortInfo;
};

type QueryOptions <TData, Variables extends BaseVariables> = {
    query: TypedDocumentNode<TData, Variables>;
}

type UseGraphQlListOptions<
    TData,
    Variables extends BaseVariables,
    ListData extends BaseListData,
> = {
    key: string;
    variables?: Omit<Variables, 'input'>;
    inputVariables?: Exclude<Variables['input'], BaseVariables['input']>;
    defaultInput?: NoInfer<Variables['input']>;
    onListInputChange: (listInput: NonNullable<Variables['input']>) => void;
    withNetworkOnlyStrategy?: boolean;
    withPagination?: boolean;
    extractList: (data: NoInfer<TData>) => ListData;
};

type UseGraphQlListResult<
    TData,
    Variables extends BaseVariables,
    ListData extends BaseListData,
> = {
    queryResult: QueryResult<TData, Variables>;
    listData: ListData | null;
    listInput: NonNullable<Variables['input']>;
    setSearchFilters: SetSearchFilters;
    setPage: SetPage;
    setItemsPerPage: SetItemsPerPage;
    setSort: SetSort;
    paginationProps: PaginationProps | undefined;
    listProps: {
        data: ListData['nodes'];
        loading: boolean;
        totalRows: number | undefined;
        listContext: GraphQlListContextType;
    },
};

const useGraphQlList = <Data, Variables extends Record<string, unknown>, TListData extends BaseListData> ({
    key,
    query,
    defaultInput,
    variables,
    inputVariables,
    withNetworkOnlyStrategy = true,
    onListInputChange,
    withPagination = true,
    extractList,
}: QueryOptions<Data, Variables> & UseGraphQlListOptions<Data, NoInfer<Variables>, TListData>,
): UseGraphQlListResult<Data, Variables, TListData> => {
    type ListInput = NonNullable<Variables['input']> & BaseVariables['input'];

    const [fetchPolicy, setFetchPolicy] = useState<FetchPolicy | undefined>();
    const [listInput, setListInput] = useLocalStorage<ListInput>(
        ...getGraphQlListInputStorageArgs({ key, withPagination, defaultInput: defaultInput as ListInput }),
    );

    const queryResult = useQuery(query, {
        variables: {
            input: { ...listInput, ...(inputVariables || {}) },
            ...(variables || {}),
        } as unknown as Variables,
        fetchPolicy,
    });
    const queryData = (queryResult.data || queryResult.previousData);
    const listData = queryData ? extractList(queryData) : null;

    const handleListInputChange = (listInput: ListInput) => {
        setListInput(listInput);
        withNetworkOnlyStrategy && setFetchPolicy('network-only');
        onListInputChange && onListInputChange(listInput);
    };

    const setSearchFilters: SetSearchFilters = filters => {
        const result = typeof filters === 'function' ? filters(listInput.searchFilters) : filters;
        handleListInputChange({
            ...listInput,
            searchFilters: result,
            pagination: { ...listInput.pagination, page: 1 },
        });
    };

    const setFilters: SetFilters = ({ searchFilters, ...filters }) => {
        const newListInput = {
            ...listInput,
            ...filters,
            pagination: { ...listInput.pagination, page: 1 },
        };

        if (listInput.searchFilters || searchFilters) {
            newListInput.searchFilters = {
                ...(listInput.searchFilters ?? {}),
                ...(searchFilters ?? {}),
            };
        }

        handleListInputChange(newListInput);
    };

    const setSort: SetSort = orderBy => handleListInputChange({ ...listInput, orderBy });

    const setPage: SetPage = page => handleListInputChange({
        ...listInput,
        pagination: { ...listInput.pagination, page },
    });

    const setItemsPerPage: SetItemsPerPage = itemsPerPage => handleListInputChange({
        ...listInput,
        pagination: {
            page: 1,
            itemsPerPage: itemsPerPage === 'all' ? null : +itemsPerPage,
        },
    });

    const paginationProps: PaginationProps | undefined = withPagination && listData ? {
        current: listData.pageInfo.current as number,
        total: listData.pageInfo.pageCount as number,
        onSelect: setPage,
        itemsPerPage: listInput.pagination?.itemsPerPage ?? 'all',
        itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
        onItemsPerPageChange: setItemsPerPage,
    } : undefined;

    return {
        queryResult,
        listData,
        listInput,
        setSearchFilters,
        setPage,
        setItemsPerPage,
        setSort,
        paginationProps,
        listProps: {
            listContext: {
                listData,
                listInput,
                setSearchFilters,
                setFilters,
                setSort,
                setPage,
                setItemsPerPage,
                paginationProps,
            },
            // List component props
            ...(listData?.sortInfo ?? {}),
            loading: queryResult.loading,
            data: listData?.nodes || [],
            totalRows: listData?.pageInfo.total,
        },
    };
};

export default useGraphQlList;
