// Copyright 1999-2025. WebPros International GmbH. All rights reserved.

const COOKIE_POLICY_LOCAL_STORAGE_KEY = 'cookie-policy';

export const hasPreferences = () => !!localStorage.getItem(COOKIE_POLICY_LOCAL_STORAGE_KEY);

export const savePreferences = preferences => {
    localStorage.setItem(COOKIE_POLICY_LOCAL_STORAGE_KEY, JSON.stringify(preferences));
};

export const loadPreferences = () => {
    let analytics = false;
    let marketing = false;
    try {
        const data = JSON.parse(localStorage.getItem(COOKIE_POLICY_LOCAL_STORAGE_KEY));
        if (typeof data.analytics === 'boolean') {
            ({ analytics } = data);
        }
        if (typeof data.marketing === 'boolean') {
            ({ marketing } = data);
        }
    } catch {}
    return {
        analytics,
        marketing,
    };
};

export const isAnalyticsAllowed = () => loadPreferences().analytics;
